.b-country-selector-modal {
    &.hidden {
        display: none;
    }

    .modal-body {
        padding-bottom: 50px;
        @media (--sm) {
            padding-bottom: 96px;
        }
    }

    .modal-title {
        font-size: 2.5rem;
        letter-spacing: 0.07em;
        text-align: center;
        margin: 0 94px;
        @media (--sm) {
            font-size: 2.3rem;
            margin: 12px 22px 0;
        }

        @if ($feature-redesign) {
            font-family: $font-family-serif;
            @include n3;
        }
    }
    
    .modal-subtitle {
        font-size: 2rem;
        letter-spacing: 0.04em;
        text-align: center;
        margin: 20px 94px 88px;
        @media (--sm) {
            font-size: 1.386rem;
            margin: 24px 66px 64px;
        }
        
        @if ($feature-redesign) {
            font-family: $font-family-serif;
            @include n3;
        }
    }

    .modal-dialog {
        width: 600px;
        @if ($feature-redesign) {
            @include n5;
        }
    }

    .form-group {
        width: 300px;
        margin: 0 auto;

    }

    .form-control-label {
        font-size: 1.333rem;
        @media (--sm) {
            font-size: 1.076rem;
        }

        @if ($feature-redesign) {
            font-family: $font-family-sans;
        }
    }

    .form-control {
        font-size: 1.23rem;
        letter-spacing: 0.03em;
        margin-bottom: 100px;
        height: 50px;
        border-color: $black;
        @media (--sm) {
            font-size: 1.33rem;
            margin-bottom: 58px;
        }
    }

    .close {
        font-size: 20px;
        padding: 24px 24px 12px;
        @media (--sm) {
            padding: 24px 20px;
        }
    }

    .modal-button {
        display: block;
        margin: 0 auto;
        width: 240px;
        padding: 16px 0;
        font-size: 1rem;
        @media (--sm) {
            font-size: 0.923rem;
        }
    }
}