.b-header-message {
    font-size: $font-size-h4;
    font-weight: 300;

    @if ($feature-redesign) {
        font-weight: normal;
        background-color: $hush-white;
    }

    letter-spacing: 0.08em;
    line-height: 20px;
    opacity: 1;
    overflow: hidden;
    text-align: center;
    transition: $transition-ease;
    transition-property: visibility;

    @media (--sm) {
        &--accept {
            text-align: left;
        }
    }

    @media (--md-down) {
        font-size: $font-size-secondary;
    }

    @media (--lg-up) {
        max-width: none;
    }

    @each $breakpoint, $gutter in $header-gutters {
        @media (--#{$breakpoint}) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    
    @if ($feature-redesign) {
        @media screen and (min-width: 540px) and ( max-width: 1200px) {
            padding: 0;
        }
    }

    &.hide {
        opacity: 0;
        transition-property: height, opacity;
    }

    &__content {
        margin-bottom: 15px;
        margin-top: 15px;
        position: relative;

        @media (--sm) {
            line-height: 15px;
            letter-spacing: 0.5px;
        }

        .d-xl-inline {
            display: none !important;
        }
    }

    &__text {
        font-size: 14px;

        @if ($feature-redesign) {
            text-align: left;
            color: $earthen-brown;
            @include n5;

            > button {
                color: $earthen-brown;
                text-align: start;
            } 

            > p {
                text-align: start;
            }

            @media screen and (max-width: 540px) {
                padding-left: rem(8) ;
            }
            
            @media screen and (min-width: 540px) and (max-width: 768px) {
                padding-left: rem(18) ;
            }

            @media screen and (min-width: 769px) and (max-width: 979px){
                padding-left: rem(20) ;
            }

            @media screen and (min-width: 980px) {
                padding-left: rem(20.8) ;
            }

            @media screen and (min-width: 1200px) {
                padding-left: rem(33) ;
            }  
        }
        
        @if not ($feature-redesign) {
            @media (--sm) {
                font-size: 11.5px;
                padding-left: 8px;
                padding-right: 8px;  
            }

            @media (--md) {
                padding: 0 30px 0 0;
            }
        }

        &-message {
            display: inline;

            @if ($feature-redesign) {
                > a {
                    color: $earthen-brown;
                }
            }
        }
    }

    &__close-button {
        font-weight: $font-weight-bolder;

        @if ($feature-redesign) {
            font-weight: normal;
        }
        line-height: 1;
        text-transform: uppercase;
        text-decoration: underline;

        @media (--sm) {
            width: 100%;

        }
    }

    &--error &__content {
        color: $red;

        &::before,
        &::after {
            border-color: rgba($red, 0.3);
        }
    }

    .cookie-policy {
        cursor: pointer;
        position: relative;
        text-decoration: none;

        &::after {
            content: '';
            width: 100%;
            border-bottom: 1px solid;
            position: absolute;
            bottom: 0px;
            left: 0;
        }
    }
}
