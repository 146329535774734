// Multicoloured Icons
//
// A less utilitarian multicoloured variation breathes life into areas of that site that require added impact.  They have been taken from and extended from a pre-existing global icon set.
//
// markup:
// <div class="sg-iconset">
//   <i class="i-clr--amex">amex</i>
//   <i class="i-clr--flag-int">flag-int</i>
//   <i class="i-clr--flag-uk">flag-uk</i>
//   <i class="i-clr--mastercard">mastercard</i>
//   <i class="i-clr--paypal">paypal</i>
//   <i class="i-clr--visa">visa</i>
// </div>

@mixin i-clr() {
    display: inline-block;
    vertical-align: middle;
    font-size: 1em;
    line-height: 1;
    background-image: url("../sprites/sprite.svg");
    background-repeat: no-repeat;
    background-size: 12.5em 6em;
}

[class*="i-clr--"]::before,
[class*="i-clr-after--"]::after {
    @include i-clr;
}

@mixin i-clr--amex() {
    content: "";
    background-position: 0em 0em; /* stylelint-disable-line */
    width: 3.19907em;
    height: 1em;
}

.i-clr--amex::before,
.i-clr-after--amex::after {
    @include i-clr--amex;
}

@mixin i-clr--flag-int() {
    content: "";
    background-position: -3.2em -1em; /* stylelint-disable-line */
    width: 1em;
    height: 1em;
}

.i-clr--flag-int::before,
.i-clr-after--flag-int::after {
    @include i-clr--flag-int;
}

@mixin i-clr--flag-uk() {
    content: "";
    background-position: -4.2em -2em; /* stylelint-disable-line */
    width: 1em;
    height: 1em;
}

.i-clr--flag-uk::before,
.i-clr-after--flag-uk::after {
    @include i-clr--flag-uk;
}

@mixin i-clr--mastercard() {
    content: "";
    background-position: -5.2em -3em; /* stylelint-disable-line */
    width: 1.38em;
    height: 1em;
}

.i-clr--mastercard::before,
.i-clr-after--mastercard::after {
    @include i-clr--mastercard;
}

@mixin i-clr--paypal() {
    content: "";
    background-position: -6.58em -4em; /* stylelint-disable-line */
    width: 3.46em;
    height: 1em;
}

.i-clr--paypal::before,
.i-clr-after--paypal::after {
    @include i-clr--paypal;
}

@mixin i-clr--visa() {
    content: "";
    background-position: -10.04em -5em; /* stylelint-disable-line */
    width: 2.46em;
    height: 1em;
}

.i-clr--visa::before,
.i-clr-after--visa::after {
    @include i-clr--visa;
}
