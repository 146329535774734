@import 'b-product-badge';

.b-product-quickview {
    @media (--md) {
        height: 100%;
    }

    &__image {
        position: relative;
        width: 100%;

        @media (--md-up) {
            float: left;
            max-width: 276px;
            min-height: 395px;
        }

        @media (--lg-up) {
            max-width: 417px;
            min-height: 597px;
        }
    }

    &__details {
        padding: 16px 25px 20px;


        @media (--md) {
            padding: 15px 17.5px 15px;
        }

        @media (--md-up) {
            overflow: hidden;
            align-self: stretch;
            display: flex;
            align-items: center;
        }

        @media (--lg-up) {
            padding: 40px;
            width: 100%;
        }
    }

    .b-product {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        &__details {
            @extend .btn;
            @extend .btn-primary;
            color: $hush-black;
            background: transparent;
            border: 1px solid $hush-black;
            display: grid;
            place-content: center;
            height: 50px;

            &:hover {
                color: $earthen-brown !important;
                border: 1px solid $earthen-brown;
            }

            @media (--md-up) {
                height: 36px;
                width: 252px;
            }

            @media (--lg-up) {
                width: 302px;
                min-height: 50px;
            }

            @media (--sm) {
                max-width: 100%;
            }
        }

        &__variations {
            margin-top: 0px;
            margin-bottom: 20px;
        }

        &__actions {
            margin-bottom: 20px;
            margin-top: 0px;

            @media (--md-up) {
                width: 302px;
                margin-bottom: 12px;
            }

            @media (--lg-up) {
                max-width: 350px;
            }
        }

        &__name {
            &.h3-1 {
                @include n5;
            }
            margin-bottom: 4px;
        }

        &__rating {
            margin-bottom: 10px;
        }

        &__availability {
            @media (--md-up) {
                margin: 21px 0px 16px;
            }

            @media (--lg-up) {
                margin-top: 35px;
            }

            margin: 16px 0px;
        }

        &__prices {
            margin-bottom: 16px;

            @media (--md-up) {
                margin-bottom: 10px;
            }

            @media (--lg-up) {
                margin-bottom: 35px;
            }
        }
    }

    .b-wishlist-button {
        @media (--md-up) {
            width: 13px;
            height: 13px;
        }

        @media (--lg-up) {
            width: 18px;
            height: 17px;
        }
    }

    .b-product-badge {
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
    }

    .b-product-variation {
        &--color + .b-product-variation {
            margin-top: 20px;

            @media (--md) {
                margin-top: 16px;
            }
        }

        &--color {
            @media (--lg-up) {
                margin-bottom: 35px;
            }
        }

        &__title {
            @include n6;
            margin-bottom: 8px;

            @media (--lg-up) {
                margin-bottom: 10px;
            }
        }

        &__title-value {
            @include n8;
            color: $gray-1000;

            &::before {
                content: "Colour";
                color: $hush-black;
                margin-right: 5px;
            }
        }

        &__content {
            width: unset;
        }

        &__swatch {
            margin-bottom: 0px;
            width: 22px;
            height: 22px;
        }

        &__button {
            @media (--md) {
                margin-bottom: 2px;
            }
        }

        &--size {
            margin-top: 0px;
        }
    }

    &.b-product-quickview--gift-certificate {
        .b-product-quickview__content {
            display: flex;
            flex-direction: column;

            @media (--md-up) {
                flex-direction: row;
            }
        }

        .b-product-quickview__image {
            position: relative;
            width: 100%;

            @media (--md-up) {
                float: none;
                margin-right: 35px;
            }
        }

        .b-product-quickview__details {
            flex-grow: 1;

            @media (--md-up) {
                overflow: visible;
            }
        }

        .b-product {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: flex-start;

            &__body {
                height: 100%;
                position: relative;
            }

            &__actions {
                margin-bottom: 0px;
                margin-top: 0px;
            }

            &__details {
                margin-top: 20px;
                max-width: 100%;

                @media (--md-up) {
                    margin-left: 260px;
                }
            }
        }
    }

    &__content {
        height: 100%;

        @media (--md-up) {
            display: flex;
            align-items: center;
            width: 100%;
        }
    }
}

.modal-quickview {
    position: static;
    margin-bottom: -10px;
    padding-left: 0px !important;
    padding-right: 0px !important;

    @media (--sm) {
        margin-top: 40px;
    }

    @media (--md-up) {
        justify-content: center;
        min-width: 620px;
        max-width: 620px;
        height: 395px;
    }

    @media (--lg-up) {
        min-width: 885px;
        max-width: 885px;
        height: 597px;
    }

    .close {
        position: absolute;
        top: -50px;
        right: 0px;
        color: $hush-white;

        @media (--md-up) {
            top: 0px;
            right: 0px;
            color: black;
            font-size: 11px;
        }

        @media (--lg-up) {
            font-size: 15px;
        }
    }

    .modal-body {
        padding-bottom: 0px;
        padding: 0px !important;
        background-color: $hush-white;

        @media (--md-down) {
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    .modal-title {
        display: none;
    }

    .modal-button {
        position: absolute;
        display: none;
        top: 25%;
        color: $white;
        height: 24px;
        z-index: 1;
        padding: 0 6px;

        @media(--md-up) {
            top: 45%;
        }

        &[data-elem-id='']{
            background-color: $gray-200;
            border: none;
        }

        &.next {
            background: none;

            @media (--md-up) {
                right: 478px;
            }

            @media (--md) {
                right: 354px;
            }

            &::before {
                content: "\EA06";
                color: $hush-black;
            }
        }

        &.prev {
            background: none;
            transform: rotate(180deg);

            @media (--md-up) {
                left: 10px;
            }

            &::before {
                content: "\EA06";
                color: $hush-black;
            }
        }
    }

    &.modal-reccomendation {
        .modal-button {
            display: none;
            border: none;

            &[data-elem-id='']{
                display: none;
            }

            @media (--md-up) {
                display: block;
            }
        }

        .recomendation-title {
            display: block;
            padding: 0 15px 20px 0;
            border-bottom: 1px solid $black;
            margin: 0 15px 30px 15px;
            display: none;

            @media (--md-up) {
                padding: 0 40px 20px 0;
                margin: 0 40px 30px 40px;   
            }
        }

        @media (--sm) {
            .b-product-picture {
                &:nth-of-type(2),
                &:nth-of-type(3) {
                    display: none;
                }
            }

            .modal-content {
                .close {
                    position: absolute;
                    top: -50px;
                    right: 0px;
                    color: white;
                }
            }

            .b-product-quickview__image {
                display: flex;
                justify-content: center;
            }
        }

        .pagination {
            display: block;
            text-align: center;
            padding-top: 20px;
            display: none;
        }

        @media (--lg) {
            min-width: 900px;
        }
    }

    .b-product-action {
        padding: 0px;

        &--wishlist {
            position: absolute;
            top: 17px;
            right: 17px;
        }

        .b-cart-button {
            &:hover {
                color: $hush-black;
                background-color: transparent;
            }

            @media (--md) {
               height: 36px;
               width: 252px;
               display: grid;
               place-content: center;
            }
        }
    }

    .b-product-availability {
        &__message {
            margin-top: 0px;
            margin-bottom: 0px;
        }

        .m-lowstock {
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }
}

:has(.modal-quickview.show) {
    .modal {
        background-color: rgba(0, 0, 0, 0.7);
    }
}