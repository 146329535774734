.b-carousel {
    $selector: &;

    overflow: hidden;
    position: relative;

    &--loaded .b-carousel__arrows {
        opacity: 1;
        transition: .2s opacity .2s;
    }
    
    &__title {
        margin-bottom: 20px;
    }
    
    &__recc-title {
        display: none;
    }

    &__arrows {
        opacity: 0;
    }

    &__arrow {
        background: transparent;
        border: 0;
        border-radius: 0!important;
        cursor: pointer;
        font-size: 14px;
        margin-top: -22px;
        padding: 15px;
        position: absolute;
        top: 50%;
        transition: 0.3s;
        box-shadow: none!important;
        border: 0!important;
        color: $black!important;
        display: inline-block!important;

        &--disabled {
            color: $gray-200!important;
            pointer-events: none;
        }

        &--prev {
            left: 0;
            .b-carousel__arrow-icon::before {
                content: icons-char("arrow-left");
            }
        }

        &--next {
            right: 0;
            .b-carousel__arrow-icon::before {
                content: icons-char("arrow-right");
            }
        }

        #{$selector}__arrow-icon {
            @if ($feature-redesign) {
                &::before {
                    color: $black;
                    transition: color 0.3s ease;
                }

                &:hover {
                    &::before {
                        color: $earthen-brown !important;
                    }
                }      
            }
        }

        @media (--sm) {
            display: none;
        }

        .b-carousel--disabled & {
            display: none!important;
        }
    }

    &__arrow-icon {
        @include icons(arrow-left);
        @include icons(arrow-right);
    }

    &__bullets {
        font-size: 0;
        margin-bottom: -5px;
        margin-top: 15px;
        text-align: center;

        .b-carousel--disabled & {
            display: none;
        }

        @if ($feature-redesign) {
            display: none !important;
        }
    }

    &__bullet {
        @include icons(point);

        background: none;
        border: 0;
        color: $gray-200;
        display: inline-block;
        font-size: 5px;
        height: 15px;
        padding: 5px;
        vertical-align: top;
        width: 15px;
        box-shadow: none !important;

        &--active,
        &--visible {
            color: $black;
        }
    }
}