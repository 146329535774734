@import "../../../../../../node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "../../../../../../node_modules/@glidejs/glide/src/assets/sass/glide.theme";

$lg: 1200px;
$sm: 768px;
$xs: 540px;
$hoverColor: #57321D;
$arrowOffset: -5rem;
$contentHeight: 100%;
$textHeight: 4rem;
$textBlack: #141110;

.editorial-carousel {
    padding-top: rem(15);
    
    &__line {
        border-top: 1px solid $textBlack;
        width: 97%;
        padding-bottom: 10px;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__items {
        display: flex;
        gap: 2rem;
        width: 100%;
        position: relative;
        align-self: center;
        padding-left: 1rem;

       
        @media screen and (min-width: $xs) {
            padding-left: 0;
            gap: 0;
            padding-bottom: 3.2rem;       
        }
        
        @media screen and (max-width: $xs) {
            padding-bottom: 2rem;       
        }

        @media screen and (min-width: $sm) {
            width: 80%;
        }  
        
        @media screen and (min-width: $lg) {
            width: 80%;
            padding-bottom: 6.89rem;
        }
        
        @media screen and (max-width: $sm) {
            gap: 0;
            padding-left: 0;
            padding-bottom: 2rem; 
        }
    }

    &__item {
        width: 100%;
        display: flex;
        flex-direction: column;
        font-size: 20px;

        &__title {
            text-transform: uppercase;
        }

        &__title, &__description {
            font-family: "ABCROM Book", Arial, sans-serif;
            font-size: 1.16rem;
            line-height: 1.6rem;
            user-select: none;
            white-space: normal;
            max-width: 100%;
            text-align: center;

            @media (min-width: $sm) {
                font-size: 1.1875rem;
                line-height: 1.5625rem;
            }
        }

        &:hover .editorial-carousel__image-container {
            opacity: 0.5;
        }
    }

    &__image-container {
        img {
            user-select: none;
            width: 100%;
            aspect-ratio: 4/3;
            object-fit: cover;
        }
    }

    &__text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.4rem;        
        height: $textHeight;

        &:hover {
            color: $hoverColor;
        }

        @media screen and (min-width: $sm) {
            justify-content: flex-end;
        }

        @media screen and (max-width: $sm) {
            justify-content: flex-start;    
            padding-top: 1rem;
        }
    }

    &__editorial-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title {
        text-transform: uppercase;
        width: 100%;
        max-width: 1676px;
        font-family: "ABCROM Book", Arial, sans-serif;
        font-size: 1.6rem;
        line-height: 2.2rem;

        @media (min-width: $sm) {
            padding: 0 11px;
            font-size: 1.625rem;
            line-height: 2.1875rem;
        }

        @media (min-width: $lg) {
            padding: 0 137px;
        }

        @media (min-width: 1440px) {
            padding: 0 150px;
            width: 100vw;
        }
        
        @media screen and (max-width: $sm) {
            margin-bottom: 10px;

            @media screen and (min-width: 768px) {
                margin-bottom: 25px;
            }
    
            @media screen and (min-width: 1200px) {
                margin-bottom: 40px;
            }
        }
    }

    &__wrapper-link {
        text-decoration: none;
        color: inherit;
        display: flex;
        flex-direction: column;
        gap: 1.49rem;
        height: 100%;
        justify-content: flex-start;

        @media screen and (max-width: $sm) {
            gap: 0.94rem;
        }

        &:hover {
            text-decoration: none;
        }
    }

    
    &__more-stories {
        display: flex;
        align-items: center;
        padding-right: 0.96rem;
        position: relative;
        padding-bottom: 0;
        
        &__container {
            border-width: 0 1.5px 0 1.5px;
            border-style: solid;
            border-color: #141110;
            height: 80%;
            width: calc(100% - 1rem);
            display: flex;
            align-items: center;
            justify-content: center;

            @media screen and (max-width: $sm) {
                border-left-width: 8.5px;
            }
        }

        &__link {
            text-decoration: none;
            user-select: none;
            height: fit-content;
            color: $textBlack;
        }

        &:hover {            
            color: $hoverColor;

            .next-carousel__more-stories__container {
                border-color: $hoverColor !important;
                background: white;
            }

            .next-carousel__more-stories__link {
                color: $hoverColor !important;
            }
        }
    }



    .glide {
        display: flex;
        justify-content: center;
        align-items: center;

        &__track {
            width: 100%;
            margin: 0 4.125rem;
            
            @media screen and (max-width: $sm) {
                margin: 0 10px 0 0;
            }
        }

        &__slides {
            gap: 1.54rem;
            
            @media screen and (max-width: $sm) {
                gap: 0.625rem;
            }
            
            div {
                margin: 0 !important;
           }
        }

        &__arrow {
            display: none;
            top: calc(($contentHeight - $textHeight) / 2.5);
            border: none;
            box-shadow: none;
            color: $textBlack;
            font-size: 2.4rem;

            @media (min-width: $sm) {
                display: block;
            }

            &[hidden] {
                display: none;
            }

            &:hover {
                color: $hoverColor;
            }

            &--left {
                left: $arrowOffset;
                transform: rotate(180deg) translateY(44%);
            }

            &--right {
                right: $arrowOffset;
            }
        }
    }
}
