.b-product-availability {
    font-size: $font-size-primary;
    @if ($feature-redesign) {
        @include n10;
    }

    &__text,
    &__message {
        &.m-instock {
            display: none;
            color: $green;
        }

        &.m-backorder,
        &.m-preorder,
        &.m-lowstock {
            color: $orange;
        }

        &.m-outofstock {
            color: $red;
        }
    }

    &__message {
        display: block;
    }

    &__message + &__message {
        margin-top: 5px;
    }
}
