.modal-background {
    @include modal-background;

    .opened-refinements & {
        @media (--md-down) {
            opacity: 0.5;
            pointer-events: initial;
            transition-delay: 0s, 0s;
            z-index: $z-refinements - 1;
        }
    }
}

html {
    font-size: $font-size-primary;

    @media (--lg-up) {
        font-size: $font-size-secondary;
    }
    @if ($feature-redesign) {
        font-family: $font-family-sans;
        font-variant-numeric: lining-nums;
        font-size: 12.5px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        @media (min-width: 544px) {
            font-size: 13.5px;
        }
        @media (min-width: 769px) {
            font-size: 12px;
        }
        @media (min-width: 992px) {
            font-size: 12.75px;
        }
        @media (min-width: 1200px) {
            font-size: 13.5px;
        }
        @media (min-width: 1800px) {
            font-size: 16px;
        }

        @media (--lg-up) {
            &.header-is-sticky, &.header-is-hovered, &.opened-search {
                .white-nav-toggle-on {
                    color: $hush-black;
                    transition: fill 250ms ease-in-out;
                    path, polygon {
                        fill: $hush-black;
                        transition: color 250ms ease-in-out;
                    }
                }
            }
        }

        @media (--md-down) {
            &.header-is-sticky, &.opened-search, &.opened-navigation {
                .white-nav-toggle-on {
                    color: $hush-black;
                    transition: fill 250ms ease-in-out;
                    path, polygon {
                        fill: $hush-black;
                        transition: color 250ms ease-in-out;
                    }
                }
            }
        }
    }
}

body {
    border-right: 0 solid $gray-100;
    overflow-y: scroll;
    word-break: break-word;
    @if ($feature-redesign) {
        background-color: $hush-white;
    }

    @media (--sm) {

        #page[data-action="Cart-Show"] {
            
            .b-header-message--accept {
                display: none;
            }
        }
    }
}

.disable-page-scroll {
    overflow: hidden;

    body {
        overflow: initial;
    }

    @supports (-webkit-overflow-scrolling: touch) { // iOS only
        &,
        body {
            left: 0;
            position: fixed;
            top: 0;
            width: 100%;
        }

        &,
        body,
        #page {
            background-color: $white;
            display: block;
            height: 100%;
            overflow: hidden;
        }
    }
}

img {
    height: auto;
    max-width: 100%;
    transition: opacity $transition-ease-short;
}

svg {
    vertical-align: baseline;
}

input,
select,
textarea {
    border-radius: 0;
}

textarea[placeholder],
input[placeholder] {
    text-overflow: ellipsis;
    
    @if ($feature-redesign) {
        font-variant-numeric: lining-nums;
    }

    &::placeholder {
        transition: color 0.3s ease;
    }

    &:focus::placeholder {
        color: transparent;
    }
}

:focus,
button:focus,
input:focus {
    outline: 0;
}

input::-webkit-search-cancel-button {
    display: none;
}

input::-ms-clear {
    display: none;
}

a, button {
    color: inherit;
}

p {
    line-height: 1.5;
}

table {
    width: 100%;
}

th {
    font-weight: 500;

    @if ($feature-redesign) {
        font-weight: normal;
    }
}

td {
    padding: 1em 0 0;
}

iframe {
    body > & {
        bottom: 0;
    }
}

.l-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content {
    @include make-container-paddings;
}

%grid-column {
    @each $key, $gutter in $grid-gutters {
        @media (--#{$key}) {
            padding-left: $gutter * 0.5;
            padding-right: $gutter * 0.5;
        }
    }
}

.bv-container {
    font-size: 0;
}

.b-page-description {
    font-size: $font-size-secondary;

    @media (--md) {
        font-size: $font-size-h4;
    }
}

.modal-open {
    #genesys-mxg-frame {
        z-index: $z-small;
    }
}