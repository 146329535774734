.b-footer-sign-up {
    $selector: &;

    &__title {
        font-family: $font-family-tiempos-fine;
        font-size: 26px;
        font-weight: 700;

        @if ($feature-redesign) {
            @include n3;
            color: $earthen-brown;
        }

        letter-spacing: 0.5px;
        margin-bottom: 6px;

        @media (--md-down) {
            margin-bottom: 12px;
        }
    }

    &__subtitle {
        font-size: 13px;
        line-height: 1.38;
        margin-bottom: 25px;
        max-width: 265px;

        @media (--md-down) {
            margin-bottom: 30px;
        }

        @if ($feature-redesign) {
            display: none;
        }
    }

    &__input {
        background: transparent;
        border: none;
        border-bottom: 1px solid $white;
        border-radius: 0;
        box-shadow: none;
        color: $white;
        font-size: $font-size-secondary;
        font-weight: 400;

        @if ($feature-redesign) {
            color: black;
            border-color: black;
            border-bottom: 1px solid black;
            padding: 3rem 0 1.8rem;
            
            @include n4;
        }

        height: 32px;
        letter-spacing: normal;
        line-height: inherit;
        margin-bottom: 15px;
        padding-left: 0;

        &.is-valid,
        &.is-invalid {
            background: none;
            border-color: transparent transparent $white;

            @if ($feature-redesign) {
                border-color: transparent transparent $black;         
            }

            &:focus {
                border-color: transparent transparent $white;
                box-shadow: none;
                
                @if ($feature-redesign) {
                    border-color: transparent transparent $black; 
                    color: $black;        
                }
            }
        }

        &.is-valid {
            border-color: $white;
            @if ($feature-redesign) {
                border-color: $black;
                border-color: transparent transparent $black;         
            }
        }

        &.is-invalid {
            @if ($feature-redesign) {
                color: $red;
                border-color: transparent transparent $red;      

                &::placeholder {
                    color: $red;
                }
            }
        }

        &:focus {
            background: none;
            border-color: $white !important;
            box-shadow: none;
            color: $white;
            @if ($feature-redesign) {
                border-color: $black !important;
                color: $black;         
            }
        }
        
        &:active {
            border-color: $white !important;
            
            @if ($feature-redesign) {
                color: $black;
                border-color: $black !important;
            }
        }

        &::placeholder {
            color: $white;

            @if ($feature-redesign) {
                color: $earthen-brown;
                @include n4;
            }
        }

        &:-webkit-autofill {
            background: transparent;
            -webkit-box-shadow: 0 0 0 1000px transparent inset;
            -webkit-text-fill-color: $white;
            
            @if ($feature-redesign) {
                -webkit-text-fill-color: $black;
            }

            transition: background-color 5000s ease-in-out 0s;
        }
    }

    &__button {
        @include icons(arrow);

        background: none;
        border: none;
        border-radius: 0;
        color: $white;
        font-size: 13px;
        opacity: 1;
        padding: 0;
        position: absolute;
        right: 0;
        top: 10px;
        
        @if ($feature-redesign) {
            top: 2.5rem;
        }

        transition: 0.3s;

        &:not(:disabled):not(.m-disabled):active,
        &:not(:disabled):focus {
            background: none;
            box-shadow: none;
        }

        &.disabled,
        &:disabled {
            background-color: transparent;
            opacity: 0.2;
        }

        @if ($feature-redesign) {
            color: $black;
        }
    }

    &__form-group {
        margin-bottom: 0;
        width: 100%;

        &.is-invalid {
            #{$selector}__form-group {
                display: none;
            }
        }
    }

    &__status {
        color: $white;
        font-size: $font-size-secondary;
        font-weight: 400;
        
        @if ($feature-redesign) {
            color: $black;
            font-weight: normal;
        }

        letter-spacing: normal;
        line-height: inherit;
        margin-top: 0;
        position: relative;

        &::before {
            left: 0;
            position: absolute;
            top: 0;
        }
    }

    &__status_invalid {
        @include icons(warning);

        padding-left: 25px;
        @if ($feature-redesign) {
            color: $red;
            padding-left: 0;
            &::before {
                display: none;
            }
        }
    }

    &__status_success {
        @include icons(success);

        padding-left: 25px;
    }

    &__input-checkbox {
        display: none;

        + #{$selector}__label-checkbox {
            color: $white;
            cursor: pointer;
            font-size: $font-size-secondary;
            font-weight: 400;

            @if ($feature-redesign) {
                font-weight: normal;
            }
            letter-spacing: normal;
            line-height: inherit;
            padding-left: 25px;
            position: relative;

            &::after {
                content: "";
            }

            &::before {
                border: 1px solid $white;
                content: "";
                display: block;
                height: 16px;
                left: 0;
                line-height: 14px;
                position: absolute;
                text-align: center;
                top: -1px;
                width: 16px;
            }
        }

        &:checked {
            + #{$selector}__label-checkbox {
                &::before {
                    content: "✓";
                }
            }
        }
    }

    &__status_success,
    &__message {
        margin-top: 10px;
    }
    
    @if ($feature-redesign) {
        &__message {
            margin-top: 0;
            padding-top: 3.5rem;
            @include n10;

            // for google privacy text
            div {
                @include n10;
            }

            @media (--md) {
                padding-top: 6.75rem;
            }
        }

        &__message-black-friday {
            padding-top: 1px;
            color: $gray-1000;
            font-size: 10px;
        }
    }
}

.blackFriday .l-footer__newsletter {
    padding-left: 16px;
    padding-right: 16px;
    @media (--md-up) {
        padding-left: 80px;
        padding-right: 80px;
    }
    @media (--lg-up) {
        padding-bottom: 96px;
    }
}

.blackFriday .l-footer__sign-up {
    @media (--lg-up) {
        width: 675px;
        margin: 0 auto;
        padding-top: 88px;
    }
}

.blackFriday .b-footer-sign-up__title {
    color: black;
}

.blackFriday .b-footer-sign-up__subtitle {
    max-width: 400px;
}

.blackFriday .b-footer-sign-up__input {
    color: black;
    @include n6;
    &::placeholder {
        color: black;
        @include n6;
    }
}

.blackFriday .b-footer-sign-up__subtitle {
    display: block;
    margin-bottom: 0;
}