@import "./next/_next-header";

.b-country-selector {
    font-size: $font-size-secondary;
    font-weight: 500;
    display: flex;

    @if ($feature-redesign) {
        font-weight: normal;
        @include n10;

        .show-next & {
            display: none;
        }
    }
    height: 28px;
    line-height: 1;
    position: relative;
    z-index: $zindex-modal;

    &__link {
        display: block;
        &:hover {
            text-decoration: none;
        }
    }

    &__country {
        .b-global-e-country-currency {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .country,
            .currency {
                margin-right: 9px;
            }

            .i-globe {
                margin-right: 12px;
            }
        }


        &--hide-on-mobile {
            display: none;
            @media (map-get($next-header-breakpoints, desktopNav)) {
                display: flex;
            }
        }
    }

    &__content {
        margin: 0 -5px;
        padding: 5px;

        .open > & {
            background-color: $white;
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
        }
    }

    &__header {
        background: none;
        border-color: rgba($white, 0.3);
        border-style: solid;
        border-width: 0 0 1px;
        color: $white;
        cursor: pointer;
        font-weight: inherit;
        min-width: 100%;
        padding: 0 10px 5px 0;
        position: relative;

        &::after {
            border-color: $white transparent;
            border-style: solid;
            border-width: 4px 3px 0;
            content: "";
            height: 4px;
            position: absolute;
            right: 0;
            top: 5px;
            width: 6px;
        }
    }

    &.open &__header {
        color: $black;

        &::after {
            border-color: $black transparent;
        }
    }

    &__button {
        background: none;
        border: 0;
        color: $gray-300;
        font-weight: inherit;
        margin: 0;
        padding: 0;
        cursor: default;

        &:focus {
            outline: 0;
        }

        &:hover,
        .m-active & {
            color: $white;

            @if ($feature-redesign) {
                color: $black;
            }
        }

        .b-country-selector__item:not(.m-active) & {
            cursor: pointer;

            .b-global-e-graphic {
                display: none;
            }
        }
    }

    &__button + &__button {
        margin-top: 10px;
    }

    &__button-text {
        pointer-events: none;
    }

    &__item {
        height: #{$global-e-flag-size + 2 * $global-e-icon-outline};

        &:first-of-type {
            border-right: 1px solid $white;
            
            @if ($feature-redesign) {
                border-right: 1px solid $black;
            }

            margin-right: #{$global-e-gutter * 7 * 0.2};
            padding-right: #{$global-e-gutter * 7 * 0.2};

            &:not(.m-active) {
                margin-left: #{$global-e-flag-size + $global-e-icon-outline};
            }
        }

        .b-global-e-int_icon {
            font-size: #{$global-e-flag-size + 2 * $global-e-icon-outline};
            margin-left: $global-e-gutter;
        }

        @if ($feature-redesign) {
            .b-global-e-label_int,
            .b-global-e-label {
                @include n10;
            }
        }

        .b-global-e-label_uk {
            margin-left: #{$global-e-gutter + $global-e-icon-outline};
        }

        .b-global-e-flag {
            width: $global-e-flag-size;
            height: $global-e-flag-size;
            overflow: hidden;
            position: relative;
            border-radius: 50%;
            outline: $global-e-icon-outline solid $white;

            img {
                height: $global-e-flag-size;
                position: absolute;
            }
        }
    }
}
