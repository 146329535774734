.table {
    th {
        @media (--sm) {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    td {
        @media (--sm) {
            padding-left: 4px;
            padding-right: 4px;
        }
    }
}

.table-scroll-wrapper {
    @include custom-scrollbar;

    margin-bottom: $spacer;
    margin-left: 95px;
    overflow-x: scroll;
    overflow-y: visible;

    @media (--md-down) {
        margin-left: 79px;
    }

    .table {
        border-left: 0;
        border-right: 0;
        margin-bottom: 0;

        td,
        th {
            &:not(.fixed-col) {
                border-left: 0;
                min-width: 60px;
            }
        }
    }

    td,
    th {
        padding-left: 10px;
        padding-right: 10px;
    }

    .fixed-col {
        left: 0;
        position: absolute;
        top: auto;
        width: 100px;

        @media (--md-down) {
            width: 80px;
        }
    }
}

.table-vertical-scroll {
    @include custom-scrollbar;

    line-height: $line-height-base;
    overflow-x: visible;
    overflow-y: scroll;
}

.b-tabs-redesign {
    .table {
        td,
        th {
            &:not(.fixed-col) {
                min-width: 78px;
                font-size: 14.38px;
                border-top: 0;
                border-right: 0; 
            }

            .fixed-col {
                border-top: 0;
            }
        }
    }
}