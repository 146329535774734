@import '../../../../../../../int_amplience/cartridge/client/src/next/next-variables.scss';

.b-navigation {
    align-items: flex-end;
    display: flex;
    font-size: $font-size-secondary;
    letter-spacing: 0.08em;
    line-height: 0.9;
    list-style: none;
    margin: 0;
    padding: 0;

    @media (--xl) {
        font-size: $font-size-primary;
    }

    @media (--xxl) {
        font-size: 16px;
    }

    @supports (-ms-accelerator:true) or (-ms-ime-align:auto) {
        font-family: $font-family-theinhardt-medium;
        @if ($feature-redesign) {
            font-family: $font-family-sans;
            font-weight: normal;
        }
    }

    @if ($feature-redesign) {
        height: fit-content;
        &:hover &__item:not(:hover) &__link {
            color: $boulder-grey;
        }

        &:hover &__item &__link {
            color: $earthen-brown;
        }
    }

    &__item {
        text-align: center;

        @if ($feature-redesign) {
            text-transform: uppercase;

            &[data-headernavelement="SALE"] {
                .b-navigation__link {
                    color: $red;

                    &:hover {
                        @if ($feature-redesign) {
                            color: $earthen-brown!important;
                        }
                    }
                }
            }

            &:hover {
              & + & .b-navigation__link,
              & ~ & .b-navigation__link {
                color: $boulder-grey;
              }
            }
        }
    }

    &__link {
        font-weight: 500;

        display: inline-block;
        padding: 0 1.5rem;
        transition: opacity $transition-ease-short;

        &:hover {
            text-decoration: none;
            @if ($feature-redesign) {
                color: $earthen-brown;
            }
        }

        &_image {
            height: 2rem;
            position: relative;
            top: 0.5rem;
        }

        @if ($feature-redesign) {
            @include n11;
            padding: 0 24px 0 0;
            white-space: nowrap;

            &:last-child {
                padding-right: 0;
            }
        }
    }

    &__image {
        display: block;
        height: 36px;
        transform: translateY(16%);

        @media (--xl) {
            height: 39px;
        }

        @media (--xxl) {
            height: 48px;
        }

        img {
            height: 100%;
            width: auto;
        }
    }

    .non-touch &__item.show &__link:hover,
    .non-touch &__item:not([data-widget]) &__link:hover {
        opacity: 0.5;
        @if ($feature-redesign) {
            opacity: 1;
        }
    }
}

.b-navigation-flyout {
    $flyout-gutter-xxl: map-get($header-gutters, xxl) - 48px;

    background-color: $white;
    color: $black;
    display: block !important;
    height: auto !important;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    text-align: left;
    top: 100%;
    transition: opacity $transition-ease-short $transition-timing;
    z-index: $z-min;
    left: map-get($header-gutters, lg)+map-get($header-logo-width, xl);
    right: $header-logo-gutter;

    @if ($feature-redesign) {
        background-color: $hush-white;
        left: 0;
        right: unset;
        max-width: 488px;
    }

    &.flyout--banners {
        left: map-get($header-gutters, lg)+map-get($header-logo-width, xl)+map-get($spacers, 4);
        right: 57px;

        @if ($feature-redesign) {
            left: 0;
            right: unset;
            max-width: 568px;
        }

        @media (--xl-all) {
            left: 0;
            right: 0;
        }

        @media (--xl-all-sm) {
            left: 0;
            right: 0;
        }

        @media (--xxl) {
            left: map-get($header-gutters, lg)+map-get($header-logo-width, xl)+map-get($spacers, 4);
            right: 286px;
        }

        @media (--xl-upp) {
            left: map-get($header-gutters, lg)+map-get($header-logo-width, xl)+map-get($spacers, 4);
            right: 146;
        }
    }

    @media (--ie) {
        overflow: hidden;
    }

    @media (--xl-all) {
        left: 0;
        right: 0;
    }

    @media (--xl-all-sm) {
        left: 0;
        right: 0;
    }

    .show > & {
        opacity: 1;
        pointer-events: initial;
        transition-delay: 0s;
        transition-duration: $transition-timing;
        z-index: $z-min + 1;
    }

    &__links {
        @if ($feature-redesign) {
            @include n7;
            text-transform: none;
            color: $boulder-grey;
            width: 204px;
            padding-bottom: 12px;
        }
    }

    &__content {

        @if $feature-redesign {
            padding: 0 40px 48px 40px;
        } @else {
            display: flex;
            justify-content: space-between;
            padding: 16px 80px 50px;

            @media (--xxl) {
                padding-bottom: 60px;
                padding-left: map-get($header-gutters, xxl) - $flyout-gutter-xxl + map-get($header-logo-width, xxl) + $header-logo-gutter;
                padding-right: map-get($header-gutters, xxl) - $flyout-gutter-xxl;
                padding-top: 35px;
                padding-left: 80px;
                padding-right: 80px;
            }

            @media (--xl-all) {
                padding-left: 80px;
                padding-right: 202px;
            }
        }

        &-links {
            display: flex;
            flex-wrap: wrap;

            @if ($feature-redesign) {
                &.nav-columns {
                    flex-direction: column;
                    max-height: 90vh;
                    overflow: hidden;
                    padding-bottom: 20px;

                    .b-navigation-flyout__links {
                        display: flex;
                        width: 500px;
                    }
                }
            }
        }

        &-banner {
            padding-left: 80px ;
        }

        .flyout--banners & {
            @media (--xl-all) {
                padding-left: 143px;
                padding-right: 143px;
            }

            @media (--xl-all-sm) {
                padding-left: 62px;
                padding-right: 62px;
            }
        }
    }

    &__asset {
        display: flex;
    }

    &__slot {
        flex-grow: 1;
        font-weight: 400;

        @if ($feature-redesign) {
            font-weight: normal;
        }
        text-transform: none;

        @media (--ie) {
            min-width: 360px;
        }
    }

    &__banners {
        display: flex;
        width: 100%;

        & > div:not(:last-child) {
            margin-right: 52px;
        }
    }

    &__cta {
        margin-top: 10px;
        display: flex;
        flex-direction: column;

        button {
            background: none;
            border: none;
            text-align: left;
            margin-top: 16px;
            font-size: 15px;
            letter-spacing: -.16px;
        }

        @if ($feature-redesign) {
            .nav-img > img {
                width: 204px;
            }

            @media (--xxl) {
                .nav-img > img {
                    width: 250px;
                }
            }
        }
    }
}

.b-navigation-submenu {
    font-size: $font-size-secondary;
    letter-spacing: 0.08em;
    line-height: 1;
    list-style: none;
    margin: 0;
    padding: 0 12px 0 0;
    width: 180px;

    @if ($feature-redesign) {
        width: 204px;
    }

    @media (--xxl) {
        font-size: 14px;
        padding-right: 28px;
        width: 226px;

        &:last-child {
            padding-right: 0;
        }
    }

    @media (--xl-all) {
        padding-right: 0px;
        width: 166px;

        @if ($feature-redesign) {
            width: 204px;
        }
    }

    @media (--xl-all-sm) {
        padding-right: 0px;
        width: 166px;
    }

    &__title {
        margin-bottom: 17px;
        margin-top: 29px;

        a {
            display: block;
        }

        @media (--xl) {
            letter-spacing: 0.08em;
        }

        &:first-child {
            margin-top: 0;
        }
    }

    &__item {
        text-transform: none;

        @if ($feature-redesign) {
            color: $textBlack;
            padding-top: 12px;
            &:first-child {
                padding-top: 0;
            }
        }
    }

    &__link {
        @if ($feature-redesign) {
            color: $textBlack;
            @include n7;
            display: block;
            width: 100%;

            &:hover {
                color: $earthen-brown;
                text-decoration: none;
                &::after {
                    font-family: $font-family-sans;
                    content: '\2192';
                    display: inline-block;
                    padding-left: 7px;
                    line-height: 1rem;
                }
            }
        }
        @else {
            letter-spacing: 0.08em;
            display: block;
            padding: 0.35rem 0;

            &,
            &:hover {
                color: $gray-400;
            }
        }
    }
}
