.b-product {
    &__rating {
        margin-bottom: 20px;
    }

    &__name {
        margin-bottom: 10px;
        @if ($feature-redesign) {
            &.h3-1 {
                @include n3;
            }
        }
    }

    &__prices {
        font-size: 18px;
        margin-bottom: 20px;
        
        @if ($feature-redesign) {
            margin-bottom: 20px;
            
            @media screen and (min-width: 1200px) { 
                margin-bottom: 40px;
            }
        }
    }

    &__promotions {
        margin-bottom: 30px;

        .l-product--set & {
            margin-top: 20px;

            > p {
                color: $gray-300;
            }
        }

        &::after {
            clear: both;
            content: "";
            display: block;
        }

        .callout {
            background-color: $black;
            clear: both;
            color: $white;
            cursor: help;
            float: left;
            font-size: $font-size-secondary;
            line-height: 1;
            padding: 5px 10px;
        }

        .callout + .callout {
            margin-top: 5px;
        }
    }

    &__variations {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        @if ($feature-redesign) {
            @include n10;
        }

        &__size-fit {
            display: flex;
            justify-content: space-between;
            width: calc(100% - 63px);
            @include n8;
            @media (--lg-up) {
                width: calc(100% - 48px);
            }
        }

        &__size-chart-variation {
            margin: 0 0 16px;
        }

        &__fit-variation {
            margin: 24px 0 16px;
        }
    }

    &__form {
        margin-top: 40px;

        .form-control {
            -webkit-appearance: none;
        }

        .form-control::placeholder {
            font-size: $font-size-secondary;
            padding-left: 10px;
        }

        .form-control:-ms-input-placeholder {
            font-size: $font-size-secondary;
            padding-left: 10px;
        }

        .form-control::-ms-input-placeholder {
            font-size: $font-size-secondary;
            padding-left: 10px;
        }

        textarea.form-control::placeholder {
            padding-top: 10px;
        }

        textarea.form-control:-ms-input-placeholder {
            padding-top: 10px;
        }

        textarea.form-control::-ms-input-placeholder {
            padding-top: 10px;
        }

        .fake-placeholder-container {
            position: relative;

            @media (--lg-up) {
                max-width: calc(100% - 60px);
            }

            .form-control {
                background: transparent;
            }
        }

        .fake-placeholder {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            color: $gray-300;
            font-weight: 300;

            @if ($feature-redesign) {
                font-weight: normal;
            }
            opacity: 1;
            font-size: 12px;
            padding: 15px 10px;
            letter-spacing: 0.08em;
            z-index: -1;
        }
    }

    &__size-chart {
        margin: 24px 0 -5px;

        .b-product-variation--size + & {
            margin-bottom: 0;
        }
    }

    &__actions {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        margin-top: 40px;

        @media (--md-down) {
            margin-top: 40px;
        }

        .b-product-action {
            &--cart {
                flex: 1;
            }

            &--wishlist {
                padding-left: 30px;

                @media (--md-down) {
                    padding-right: 15px;
                }
            }
        }

        .b-cart-button {
            padding: 16px;
            width: 100%;

            .l-product--gift-certificate & {
                width: auto;
                min-width: 260px;

                @media (--sm) {
                    width: 100%;
                }
            }

            @if not ($feature-redesign) {
                @media (--sm) {
                    font-size: $font-size-primary;
                }
            }
        }

        &--unavailable {
            margin-top: 0;
            position: relative;
            @media (--md-down) {
                margin-top: 0;
            }

            .b-product-action--wishlist {
                position: absolute;
                left: calc(100% - 63px);
                bottom: 13px;
            }
        }

    }

    &__tabs {
        margin-top: 50px;
    }

    .b-product-availability {
        &__message {
            margin: 20px 0;
        }
    }
}

.modal-size-guide {
    line-height: 1.4;

    .modal-title {
        font-weight: normal;
    }

    p {
        line-height: inherit;
    }

    table {
        margin-bottom: 30px;

        th {
            max-width: 95px;

            @media (--md-down) {
                font-size: $font-size-secondary;
            }
        }

        th,
        td {
            border: 1px solid $gray-100;
        }

        tr:nth-child(2) {
            td {
                font-weight: 500;

                @if ($feature-redesign) {
                    font-weight: normal;
                }
            }
        }

        tr:first-child {
            th,
            td {
                border-color: transparent;
                border-bottom-color: $gray-100;
                padding-bottom: 10px;

                @media (--sm) {
                    font-size: 10px;
                }
            }

            th {
                border-bottom-color: transparent;
            }
        }
    }

    .table-vertical-scroll {
        margin-bottom: 30px;
        max-height: 225px;

        table {
            margin-bottom: 0;
        }
    }
}

.size-guide-redesign {
    
    .modal-body {
        @media (--sm) {
            padding-right: 0px;
        }

        @media (--sm-up) {
            padding-right: 20px;
        }
    }

    table {
        border-color: none;

        tr:first-child {
            th,
            td {
                @media (--sm) {
                    font-size: 14.38px;
                }
            }

            td {
                border-color: $light-grey;
            }

            th {
                border-color: none;
            }

        }
    }
}
