@use "sass:math";

.b-carousel {
    $selector: &;

    &--product {
        margin-bottom: 50px;
        width: auto;

        &:last-child {
            margin-bottom: 40px;

            @media (--md-up) {
                margin-bottom: 50px;
            }
        }

        @each $breakpoint, $margins in $carousel-margins {
            $left: nth($margins, 1);
            $right: nth($margins, 2);

            @media (--#{$breakpoint}) {
                margin-left: $left;
                margin-right: $right;
            }
        }

        #{$selector} {
            &__container {
                @each $breakpoint, $paddings in $carousel-paddings {
                    $left: nth($paddings, 1);
                    $right: nth($paddings, 2);

                    @media (--#{$breakpoint}) {
                        padding-left: $left;
                        padding-right: $right;

                        @if (($right - $left) > 0) {
                            &::before {
                                margin-right: -#{$right - $left};
                            }
                        }
                    }
                }

                @media (--xl) {
                    margin-left: auto;
                    margin-right: auto;
                    max-width: 100%;
                    width: map-get($container-max-widths, "xl");
                }

                &::before {
                    display: block;
                    margin-bottom: 50px;

                    &,
                    .m-bordered-carousel ~ & {
                        content: "";
                    }
                }
            }

            &__title {
                @each $breakpoint, $paddings in $carousel-paddings {
                    $left: nth($paddings, 1) - map-get($container-gutters, $breakpoint);
                    @if ($left > 0) {
                        @media (--#{$breakpoint}) {
                            margin-left: -#{$left};
                        }
                    }
                    $right: nth($paddings, 2) - map-get($container-gutters, $breakpoint);
                    @if ($right > 0) {
                        @media (--#{$breakpoint}) {
                            margin-right: -#{$right};
                        }
                    }
                }
            }

            &__content {
                @each $breakpoint, $gutter in $carousel-gutters {
                    $gutter-half: $gutter * 0.5;
                    @media (--#{$breakpoint}) {
                        margin-left: -#{$gutter * 0.5};
                        margin-right: -#{$gutter * 0.5};
                    }
                }

                position: relative;

                &.loading {
                    display: none;
                }
            }

            &__slide {
                @each $breakpoint, $gutter in $carousel-gutters {
                    $gutter-half: $gutter * 0.5;
                    @media (--#{$breakpoint}) {
                        padding-left: $gutter-half;
                        padding-right: $gutter-half;
                    }
                }

                @media (--lg-up) {
                    width: percentage(1*0.2);
                }

                @media (--md) {
                    width: percentage(math.div(1, 3));
                }

                &--peeking {
                    position: relative;

                    &::after {
                        content: '';
                        position: absolute;
                        z-index: 1;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        background-image: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255, 1) 90%);
                        @if ($feature-redesign) {
                            background-image: linear-gradient(to right, rgba($hush-white,0), rgba($hush-white, 1) 90%);
                        }
                    }
                }
            }

            &__arrows {
                position: absolute;
                top: -38px;
                right: 0;

                &__pos-reset {
                    position: unset;
                }

                @each $breakpoint, $paddings in $carousel-paddings {
                    $right: nth($paddings, 2) - map-get($container-gutters, $breakpoint);
                    @if ($right > 0) {
                        @media (--#{$breakpoint}) {
                            right: -#{$right};
                        }
                    }
                }

                @each $breakpoint, $gutter in $carousel-gutters {
                    @media (--#{$breakpoint}) {
                        margin-right: $gutter * 0.5;
                    }
                }
            }

            &__arrow {
                position: static;
                margin: 0 -5px 0 5px;
                padding: 2px 5px;

                @if ($feature-redesign) {
                    text-shadow: none;
                }

                @media (--sm) {
                    display: block;
                    visibility: hidden;
                    pointer-events: none;
                }

                &__mid {
                    position: absolute;
                    margin: 0;
                    width: 24px;
                    height: 24px;
                    padding: 0;
                    top: calc(50% - 50px);
                    @if not ($feature-redesign) {
                        background-color: $black;
                    }

                    @media (--sm) {
                        display: block;
                    }

                    #{$selector}{
                        &__arrow-icon {
                            color: $white;
                            
                            @if ($feature-redesign) {
                                color: $earthen-brown;
                            }
                        }

                        &--prev {
                            margin-left: -5px;
                        }

                        &--next {
                            margin-right: 100px;
                        }
                    }

                    &.b-carousel__arrow {
                        &--disabled{
                            color: $white;
                            pointer-events: none;
                            @if not ($feature-redesign) {
                                background-color: $gray-200;
                            }
                        }

                        &--next{
                            @if not ($feature-redesign) {
                                right: 80px;
                                @media (--sm) {
                                    right: 15%;
                                }
                            }
                        }
                    }

                    @if ($feature-redesign) {
                        .b-carousel__arrow-icon:hover::before {
                            color: $earthen-brown;
                        }
                    } @else {
                        .b-carousel__arrow-icon {
                            &::before, &:hover::before {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }

        #{$selector} {
            &__bullets{
                display: flex;
                justify-content: center;
                padding-top: 15px;
            }

            &__bullet {
                padding: 0;
                background-color: $gray-85;
                padding-left: 5px;
                height: 8px;
                width: 40px;
                margin-right: 2px;
                border-radius: 0;
                &:hover, &:focus {
                    background-color: $gray-85;
                    border: none;
                }
                
                &--visible {
                    background-color: $black;
                    &:hover, &:focus {
                        background-color: $black;
                    }
                }
                @media (--sm) {
                    display: inline;
                }

                &:before {
                    content: none;
                }
            }
        }

        .glider-track {
            box-sizing: content-box;
        }

        .b-carousel__title {
            @if ($feature-redesign) {
                @include n5;
                text-transform: uppercase;
            }
        }

        .b-carousel__track {
            &.loading {
                padding-bottom: 55px;
            }
        }

        @media (--sm) {
            &[data-per-view]:not([data-per-view="1"]) {
                margin-right: nth(map-get($carousel-margins, sm), 1);
                width: calc(100% - nth(map-get($carousel-margins, sm), 1));

                #{$selector} {

                    &__title {
                        margin-right: 0;
                    }

                    &__slide {
                        width: percentage(1*0.5);
                    }
                }

                .b-carousel__track {
                    width: calc(100% + nth(map-get($carousel-margins, sm), 1));
                }
            }
        }

        @if ($feature-redesign) {
            .b-carousel__arrow {
                background-color: transparent;
    
                &--prev {
                    ::before {
                        transform: rotate(180deg);
                    }
                }
    
                &--disabled { 
                    opacity: 0;
                    pointer-events: none;
                }
    
                .b-carousel__arrow-icon {
                    @include icons(arrow);
    
                    &::before {
                        color: $black;
                        transition: color 0.3s ease;
                    }
                }
            }
        }
    }

    &--default {
        .b-carousel__arrows {
            @media (--sm) {
                display: none;
            }
        }

        .b-carousel__arrow {
            display: unset;
            background-color: transparent;

            &--prev {
                ::before {
                    transform: rotate(180deg);
                }
            }

            &--disabled { 
                opacity: 0;
                pointer-events: none;
            }

            .b-carousel__arrow-icon {
                @include icons(arrow);

                &::before {
                    color: $black;
                    transition: color 0.3s ease;
                }

                &:hover {
                    &::before {
                        color: $earthen-brown;
                    }
                }
            }
        }

        .b-carousel__arrow__mid {
            &.b-carousel__arrow {
                &--prev {
                    left: 0px;
                }

                &--next {
                    right: 0px;
                }

                &--disabled { 
                    display: none;
                }

                @if not ($feature-redesign) {
                    @media (min-width: 1440px) {
                        &--prev {
                            left: -80px;
                        }
        
                        &--next {
                            right: -80px;
                        }
                    }
                }
            }
        }

        .b-carousel__title {
            @if ($feature-redesign) {
                @include n5;
                text-transform: uppercase;
            }
        }
    }

    &[data-ref="recomendationCarousel"] {
        @media (--md) {
            .b-carousel__arrow {
                &--prev {
                    left: -20px; 
                }
                &--next {
                    right: -30px; 
                }
            }
        }
        @media (--lg-up) {
            .b-carousel__arrow {
                $offset: -35px;
                &--prev {
                    left: $offset; 
                }
                &--next {
                    right: $offset; 
                }
            }
        }
    }

    // homepage search carousel arrows position adjustment
    &[data-widget="searchCarousel"] {
        @media (--lg-up) {
            .b-carousel__arrow {
                $offset: -35px;
                &--prev {
                    left: $offset !important; 
                }
                &--next {
                    right: $offset !important; 
                }
            }
        }
    }
}

@if ($feature-redesign) {
    [data-pageid*="homepage-template"] {
        .b-carousel--product {
            .b-carousel__content {
                height: 100%;
            }

            .b-carousel__arrows {
                $offset: -59px;
                position: absolute;
                top: 0;
                height: 100%;
                width: 100%;
                pointer-events: none;

                .b-carousel__arrow {
                    pointer-events: all;

                    &--prev {
                        left: $offset;
                    }

                    &--next {
                        right: $offset;
                    }
                }
            }

            .b-carousel__arrow__mid .b-carousel__arrow-icon {
                &::before, &:hover::before {
                    color: $black;
                    text-shadow: none;
                }
            }
        }
    }

    .blackFriday {
        .b-carousel--product {
            margin-bottom: 12px;
        }

        .b-carousel--product .b-carousel__title {
            text-transform: none;
            margin-bottom: 48px;
            @include n3;
        }

        .b-carousel__track.loading {
            padding-bottom: 0;
        }
    }
}

#pdp-recommendation-carousel,
#pdp-recently-viewed-carousel,
#pdp-duel-carousel {

    .b-carousel--product {

        .b-carousel__track.loading {
            padding-bottom: 0;
        }

        .b-carousel__container {

            &::before {
                margin-bottom: 0;
            }
        }
    }
}

