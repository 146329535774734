.form-control {
    @include form-control;

    &:-webkit-autofill {
        background: transparent;
        -webkit-box-shadow: 0 0 0 1000px $white inset;
        -webkit-text-fill-color: $black;
        transition: background-color 5000s ease-in-out 0s;
    }

    // Unstyle the caret on `<select>`s in IE10+.
    &::-ms-expand {
        background-color: transparent;
        border: 0;
    }

    // Remove select outline from select box in FF
    &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 $input-color;
    }

    &::placeholder {
        color: $gray-300;
        font-weight: 300;

        @if ($feature-redesign) {
            font-weight: normal;
        }
        // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
        opacity: 1;
    }

    &:not(:disabled):active,
    &:not(:disabled):focus {
        @include form-control--focus;
    }

    &.is-invalid {
        border-color: $red;
    }

    &:disabled,
    &[readonly] {
        @include form-control--disabled;

        // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
        opacity: 1;
    }
}

.form-group {
    margin-top: 30px;

    &:first-child {
        margin-top: 0;
    }

    &--smallGap {
        margin-top: 20px;
    }

    .form-group-wrapper & {
        margin-top: 0;
        max-width: calc(50% - 10px);
        width: 100%;

        @media (--lg-up) {
            max-width: 180px;
            margin-left: 20px;
        }
    }
}

.form-group-wrapper {
    display: flex;
    justify-content: space-between;

    @media (--lg-up) {
        justify-content: flex-start;
        margin-left: -20px;
    }
}

.form-control-file,
.form-control-range {
    display: block;
    width: 100%;
}

.form-text,
.invalid-feedback {
    @include form-text;
}

.form-text {
    a {
        color: inherit;
        text-decoration: underline;

        .non-touch & {
            opacity: 1;
            transition: opacity $transition-ease;

            &:hover {
                opacity: 0.5;
            }
        }
    }
}

.invalid-feedback {
    color: $red;
    display: none;
}

.invalid-tooltip {
    display: none;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
    display: block;
}

label {
    color: $black;
    font-size: 16px;
    font-weight: 300;

    @if ($feature-redesign) {
        font-weight: normal;
    }
    letter-spacing: 0.08em;
    margin-bottom: 5px;

    @media (--md-down) {
        font-size: 14px;
    }

    &.custom-control-label {
        cursor: pointer;
        font-size: $font-size-secondary;
        font-weight: 300;

        @if ($feature-redesign) {
            font-weight: normal;
        }
        letter-spacing: 0.12px;
    }

    .required &::after {
        color: $red;
        content: "*";
    }
}

textarea.form-control {
    border: 1px solid $gray-200;
    height: 100px;
    line-height: $line-height-medium;
    padding: 8px 12px;
    resize: none;

    &::placeholder {
        font-size: $font-size-secondary;
    }
}

.custom-control-input {
    display: none;

    + .custom-control-label {
        @include form-checkbox-label;
    }

    &:checked + .custom-control-label {
        @include form-checkbox-label--checked;
    }

    &:disabled + .custom-control-label {
        @include form-checkbox-label--disabled;
    }
}

.form-check-input {
    display: none;

    + .form-check-label {
        align-items: baseline;
        cursor: pointer;
        display: inline-flex;
        font-size: 16px;
        margin-bottom: 0;

        @media (--md-down) {
            font-size: 14px;
        }

        &::before {
            border: 1px solid $gray-200;
            border-radius: 50%;
            content: "";
            cursor: pointer;
            display: inline-flex;
            height: 10px;
            margin-right: 20px;
            min-width: 10px;
            width: 10px;
        }
    }

    &:checked + .form-check-label::before {
        background-color: $black;
        border-color: $black;
    }
}

.custom-select {
    appearance: none;
    background: url(../images/arrow-right.svg) center right no-repeat;
    cursor: pointer;
    outline: 0;
    padding-right: 20px;

    &::-ms-expand {
        display: none;
    }

    @media (--sm) {
        background-size: 10px 10px;
    }
}

#notifyMeFormWrapper {
    button.m-disabled { 
        background-color: $black;
        color: $white;
        border-color: transparent;
    }

    .b-notifyMe__success {
        width: calc(100% - 63px);
        margin: 20px 0 10px 0;
        color: $gray-1000;
        @include n8;
    }

    #notifyMe {
        width: calc(100% - 63px);
        
        .form-control {
            height: 30px;
            margin: 20px 0 10px 0;
            background-color: transparent;
            border-color: $hush-black;
        }
    
        label[for="notifyMeInput"] {
            display: none;
        }
    
        .b-notifyMe {

            &__message {
                color: $gray-1000;
                @include n8;
            }

            &__error--message {
                padding: 0;
            }

            &__success {
                display: none;
                color: $gray-1000;
                @include n8;
            }
        }
    
        &.is-invalid {
            
            .b-notifyMe__message {
                display: none;
            }
        }
    
        #notifyMeInput {
    
            &.is-invalid {
                border-color: $red;
            }
        }
    }
}
