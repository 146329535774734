@include h-styles;

.h1-1 {
    @include h1-1;
}

.h1-2 {
    @include h1-2;
}

.h1-3 {
    @include h1-3;
}

.h1-4 {
    @include h1-4;
}

.h2 {
    @include h2;
}

.h3-1 {
    @include h3-1;
}

.h3-2 {
    @include h3-2;
}

.h4 {
    @include h4;
}

.h5-1 {
    @include h5-1;
}

.h5-2 {
    @include h5-2;
}

.h6 {
    @include h6;
}

.h7 {
    @include h7;
}

.h8 {
    @include h8;
}

.h-special {
    @include h-special;
}
