.b-header-icons {
    display: flex;

    line-height: 1;
    margin-bottom: -2px;
    user-select: none;

    @if ($feature-redesign) {
        margin-bottom: 0;
        font-size: 15px;

    }

    @else {

        font-size: 13px;

        @media (--md) {
            font-size: 17px;
        }

        @media (--sm) {
            font-size: 16px;
        }

        @media (--xxl) {
            font-size: 16px;
        }
    }

    &__item {

        &.b-header-icons__search-container {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            cursor: pointer;

            @media (--sm) {
                display: none;
            }
        }

        @if ($feature-redesign) {
            width: 1.846rem;
            height: 1.846rem;
            display: flex;
            justify-content: center;
            align-items: center;

            &__login,
            &__wishlist,
            &__user-in {
                display: none;
            }

            &:not(:first-child) {
                margin-left: 20px;
            }

            .opened-navigation & {

                &__wishlist,
                &__login,
                &__user-in {
                    display: flex;
                }
            }

            @media (map-get($next-header-breakpoints, md)) {
                &__login,
                &__user-in {
                    display: flex;
                }
            }

            @media (map-get($next-header-breakpoints, lg)) {
                &__wishlist {
                    display: flex;
                }
            }

            @media (--md-up) {
                &:first-child {
                    display: flex;
                }
            }
        }

        @else {
            margin-left: 16px;

            @media (--xxl) {
                margin-left: 20px;
            }

            @media (--md) {
                margin-left: 22px;
            }

            @media (--sm) {
                &__login,
                &__user-in {
                    display: none;
                }
            }
        }
    }

    &__link {
        position: relative;

        @if ($feature-redesign) {
            &:hover svg path {
                text-decoration: none;
                fill: $earthen-brown;
            }

            &:hover span {
                background-color: $earthen-brown;
            }
        }

        &:hover {
            text-decoration: none;
        }

        &--wishlist {
            &.m-active {
                &::before {
                    content: icons-char(wishlist-filled);
                }
            }

            @if ($feature-redesign) {
                &::before {
                    display: none;
                }
    
                &.m-active > .wishlist-icon {
                    display: none;
                }
    
                &.m-active > .wishlist-icon--filled {
                    display: inline;
                }
            }
        }

        &--wishlist > .wishlist-icon--filled {
            display: none;
        }

        svg {
            height: 1.285rem;
            width: 1.285rem;
            vertical-align: middle;

            &:hover path {
                fill: $earthen-brown;
            }

            &:hover ~ span {
                background-color: $earthen-brown;
            }
        }

        @if ($feature-redesign) {

            img {
                height: 1.285rem;
                width: 1.285rem;
            }

            &.i-bag:before, &.i-user-in:before {
                display: none;
            }
        }
    }

    &__button {
        background: none;
        border: 0;
        color: inherit;
        font-size: inherit;
        padding: 0 0 0 1px;

        &--label {
            padding-left: $search-label-gutter;
            padding-right: #{$search-label-gutter * 12};
            margin-left: #{$search-label-gutter * 2.5};
            font-size: $font-size-primary;
            font-weight: $font-weight-bolder;

            @if ($feature-redesign) {
                display: none;
            }

            border-bottom: 1px solid;
            line-height: 0.9;
            letter-spacing: 0.08em;

            .opened-search & {
                display: none;
            }
        }

        &--close {
            @if ($feature-redesign) {
                width: 1.846rem;
                height: 1.846rem;
            }

            &.m-active {
                font-size: 0.95em;

                .touch & {
                    pointer-events: none;
                }

                &::before {
                    content: icons-char(close);
                }
            }
        }

        svg {
            height: 1.285rem;
            width: 1.285rem;
            vertical-align: middle;

            &:hover path {
                fill: $earthen-brown;
            }
        }

        @if ($feature-redesign) {
            img {
                height: 1.285rem;
                width: 1.285rem;
            }
        }
    }

    &__text {
        font-size: $font-size-primary;
        font-weight: 500;

        @if ($feature-redesign) {
            font-weight: normal;
        }

        letter-spacing: 0.08em;

        @media (--lg) {
            font-size: $font-size-secondary;
        }

        @media (--xxl) {
            font-size: 16px;
        }
    }

    &__sub {
        align-items: center;
        background-color: $black;
        border-radius: 100%;
        color: $white;
        display: flex;
        font-size: 10px;
        height: 18px;
        justify-content: center;
        min-width: 18px;
        opacity: 0;
        padding: 0 3px;
        position: absolute;
        right: -8px;
        top: -6px;
        
        @if ($feature-redesign) {
            font-family: $font-family-sans;
        }

        div[data-count]:not([data-count^="0"]) & {
            opacity: 1;
        }
    }
}