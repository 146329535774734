$carousel-image-gutters: (
    xl: 12px,
    lg: 10px,
    md: 10px,
    sm: map-get($container-gutters, sm) * 2
);

.b-carousel {
    $selector: &;

    &--image {
        margin-bottom: 30px;
        overflow: visible;

        @media (--sm) {
            margin-bottom: 45px;
        }

        @each $breakpoint, $gutter in $carousel-image-gutters {
            $gutter-half: $gutter * 0.5;

            @media (--#{$breakpoint}) {
                #{$selector}__content {
                    margin-left: -$gutter-half;
                    margin-right: -$gutter-half;
                }

                #{$selector}__slide {
                    padding-left: $gutter-half;
                    padding-right: $gutter-half;
                }
            }
        }

        #{$selector} {
            &__slides {
                min-width: 100%;
            }

            &__slide {
                margin: 0 auto;

                @media (--lg-up) {
                    width: percentage(1*0.5);
                }
            }

            &__arrow {
                opacity: 0;
                transition: opacity $transition-ease-short;

                @media (--xxl) {
                    font-size: 23px;
                }

                @media (--md-down) {
                    display: none;
                }

                @media (--ie) {
                    transform: translate3d(0, 0, 0);
                    transition: none;
                }
            }

            &__bullets {
                display: inherit;
                left: 0;
                position: absolute;
                right: 0;
                top: 100%;
                transform: none;
            }
        }

        .non-touch &:hover #{$selector} {
            &__arrow {
                opacity: 1;
                &--disabled {
                    opacity: 0.3;
                }
            }
        }

        // to avoid appearance of extra slides during initialisation
        @media (--ie) {
            .b-product-picture {
                &__picture,
                &__image {
                    transition: none;
                }
            }
        }
    }
}
