.b-global-e {
    margin-top: 7px;
}

.b-global-e-button {
    display: block;
    font-size: 0;
    vertical-align: top;

    &__image {
        vertical-align: top;
    }
}

.globale-selector {
    &.menu-item {
        @media (max-width: 768.98px) {
            border-top: none;
        }
        .btn {
            @media (max-width: 768.98px) {
                color: #444;
                border: none;
                padding: 0;
            }
        }
    }
}

#FreeShippingBanner {
    z-index: 3 !important;
}