$minicart-width: 396px;
$minicart-padding: 36px;

.b-reviews,
.b-minicart {
    background: $white;
    bottom: 0;
    display: flex;
    flex-direction: column;
    font-size: $font-size-primary;
    max-width: 100%;
    overflow: hidden;
    padding: 30px $minicart-padding 35px;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    transform: translate3d(100%, 0, 0);
    transition: transform $transition-ease-short 0s;
    width: $minicart-width;
    z-index: $z-minicart;

    @if ($feature-redesign) {
        background: $hush-white;
    }

    @media (--lg-up) {
        font-size: $font-size-secondary;
    }

    .opened-minicart & {
        pointer-events: initial;
        transform: translate3d(0, 0, 0);
    }

    .opened-reviews & {
        pointer-events: initial;
        transform: translate3d(0, 0, 0);
    }

    &__header {
        align-items: baseline;
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
    }

    &__title {
        font-family: $font-family-base;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: normal;
        margin-bottom: 0;
        @if ($feature-redesign) {
            @include n5;
        }
    }

    &__subtitle {
        @include line-clamping(1);

        color: $gray-300;
        flex: 1;
        margin: 0 10px;

        @if ($feature-redesign) {
            @include n9;
        }
    }

    &__close {
        @if ($feature-redesign) {
            @include n10;
        }
    }

    &__content {
        @include custom-scrollbar;
        flex: 1;
        margin-right: -#{$minicart-padding - 4px};
        overflow: auto;
        padding-bottom: 10px;
    }

    &__footer {
        &::before {
            border-top: 1px solid $gray-100;
            content: "";
            display: block;
            margin: 0 -#{$minicart-padding} 30px;
        }
    }

    &__total {
        font-size: 18px;
        font-weight: 500;
        @if ($feature-redesign) {
            @include n5;
        }
        text-align: center;
    }
    
    &__actions {
        margin-top: 30px;
    }

    &__action {
        margin-bottom: 10px;
    }

    .b-product-card {
        max-width: $minicart-width - 2 * $minicart-padding;
        padding-bottom: 30px;

        .bundled-line-item {
            margin-top: 15px;

            &__image-wrapper {
                width: 78px;
                margin-right: 10px;
            }

            &__details {
                flex: 1;
            }

            .line-item-name {
                font-size: $font-size-primary;
                font-weight: 500;
                @if ($feature-redesign) {
                    font-weight: normal;
                }
                letter-spacing: 0.03em;
                margin-bottom: 10px;
            }
        }

        &.b-cart-item--product-bundle {
            .strike-through {
                display: none;
            }
        }
    }

    .b-product-card + .b-product-card {
        border-top: 1px solid $gray-100;
        padding-top: 30px;
    }

    &__errors {
        color: $red;
        margin-bottom: 20px;
    }
}

.b-reviews {
    min-width: 33%;
}

.b-reviews,
.b-minicart {
    @media (max-width: 420px) {
        top: 8px;
        right: 6px;
        left: 6px;
        bottom: 8px;
        width: calc(100% - 16px);

        .opened-reviews & {
            pointer-events: initial;
            transform: translate3d(-2px, 0, 0);
        }
    }
}

.b-minicart {
    &__mobile-header, &__total-mobile {
        display: none;
    }

    @media (--md-down) {
        top: auto;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 24px;
        transform: translate3d(0, 100%, 0);
        gap: 24px;
        transition: transform 0.7s 0s;

        .opened-minicart & {
            transition-delay: .2s
        }
    
        &__content {
            overflow: hidden;
            padding-bottom: 0;
    
            .b-product-card {
                &__content * {
                    @include n7;
                    font-size: 15px;
                    line-height: 21.5px;
                }

                &:not(:first-child) {
                    display: none;
                }

                &:first-child {
                    padding-bottom: 0;
                }

                &__quantity {
                    margin-top: 0;
                    span {
                        display: block;
                        color: $gray-1000;
                    }
                }

                .b-quantity-field {
                    display: none;
                }

                &__price {
                    margin-bottom: 0;
                }

                .b-product-availability {
                    margin-top: 0;
                    display: none;
                }

                &__total, &__remove {
                    display: none;
                }

                &__attribute {
                    margin-top: 0;
                }

                &__attribute-group {
                    color: $gray-1000;
                }

                &__attribute-name {
                    display: inline-block;
                }

                &__image {
                    width: 120px;
                    margin-right: 10px;
                }
            }
    
            @media (--md-up) {
                overflow: auto!important;
            }
        }
    
        &__title {
            @include n3;
        }
    
        &__header, &__total {
            display: none;
        }

        &__total-mobile {
            display: flex;
            justify-content: space-between;
            @include n7;
            font-size: 15px;
            line-height: 21.5px;
            margin-bottom: 10px;
        }
    
        &__mobile-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
    
            button::before {
                position: relative;
                background-color: transparent;
            }
    
        }
    
        &__actions {
            margin-bottom: 0;
            margin-top: 0;
        }
    
        &__footer:before {
            content: none;
        }
    }
}

.modal-remove {
    max-width: 480px;

    .product-to-remove {
        font-weight: 500;

        @if ($feature-redesign) {
            font-weight: normal;
        }
    }
}

.opened-minicart .l-header__modal-background {
    transition-duration: 0.7s;
    transition-delay: .2s;
}