.b-footer-navigation {
    $selector: &;
    display: flex;
    
    @if ($feature-redesign) {
        flex-direction: row;
        gap: 4rem;
        justify-content: space-between;
    }
    
    @media (--sm) {
        font-size: $font-size-secondary;
        flex-direction: column;
        justify-content: space-between;
        
        @if ($feature-redesign) {
            gap: 3.6rem;

            div:first-of-type {
                ul:first-of-type {
                    opacity: 1;         
                    display: block !important;  
                }
            }
        }
    }
    
    @media (--md) {
        display: flex;

        @if ($feature-redesign) {
            flex-flow: wrap;
            flex-direction: row;
            justify-content: left;
            gap: 5rem;
        }
    }
    &__list {
        @media (--lg-up) {
            margin-right: 34px;
        }

        @media (--md) {
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
            @if ($feature-redesign) {
                gap: 20px;
            }
        }
           
        @if ($feature-redesign) {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }

    &__label-switcher {
        bottom: 0;
        cursor: pointer;
        display: none;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    &__switcher {
        display: none;

        &:checked {
            ~ #{$selector}__title > #{$selector}__label-switcher {
                display: block;
            }
        }
    }

    &__item {
        margin-bottom: 11px;
        @if ($feature-redesign) {
            margin-bottom: 5px;
            color: $hush-black;
            @include n5;
        }

        @media (--md) {
            margin-bottom: 10px;
            @if ($feature-redesign) {
                margin-bottom: 12px;
            }
        }

        @media (--sm) {
            margin-bottom: 30px;
            margin-top: 10px;

            @if ($feature-redesign) {
                margin-top: 0px;
                margin-bottom: 12px;
            }


            &:last-child {
                margin-bottom: 0;
            }

        }

        @if ($feature-redesign) {
            @media (--lg-up) {
                    @include n9;
            }
        }
    }

    &__title {
        background: none;
        border: 0;
        color: $white;
        cursor: pointer;
        display: block;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.92px;
        line-height: inherit;
        margin: 0;
        padding: 20px 0;
        position: relative;
        text-align: left;
        text-transform: uppercase;
        width: 100%;
        
        @if ($feature-redesign) {
            @include n11;
            color: $hush-black;
            padding: 0 !important;
        }
        
        @media (--md-up) {
            display: none;

            @if ($feature-redesign) {
                display: block;
            }
        }

        @media (--lg-up) {
            @if not ($feature-redesign) {
                font-size: 1.67rem;
            }
        }
    }

    @if ($feature-redesign) {
        ul > li {
            margin: 0;
            a {
                text-decoration: none;
                display: block;
                height: 100%;
                width: 100%;
                white-space: nowrap;
                color: $hush-black;
                @include n5;
                }
            }
            
        @media (--lg-up) {
            ul > li {
                a {
                    @include n9;
                }
            }
        }
    }

    &__link {
        &,
        &:hover {
            color: inherit;
        }
    }

    &__content {
        list-style-type: none;
        margin: 0;
        padding: 0;
      
        @if ($feature-redesign) {       
            li:first-of-type {
                margin-top: 1.35rem;
            }
        }
        
        @media (--md-up) {
            display: block !important;
        }

        @media (--sm) {
            margin-left: 20px;
            opacity: 0.5;
            
            @if ($feature-redesign) {
                opacity: 1;
                margin-left: 0px;
            }

            overflow: hidden;
            transition: $transition-ease;
            transition-property: max-height, height, opacity;
            will-change: max-height, height, opacity;

            &::after {
                content: "";
                display: block;
                height: 50px;
            }

            &.hide {
                opacity: 0;
            }

            input ~ & {
                max-height: 0;
                opacity: 0;
            }

            input:checked ~ & {
                max-height: 500px;
                opacity: 0.5;
            }
        }
    }

    @if ($feature-redesign) {
       &__link {
            text-decoration: underline;
       }
    }
}
