@mixin form-label() {
    color: $black;
    font-size: 16px;
    font-weight: 300;

    @if ($feature-redesign) {
        font-weight: normal;
    }
    letter-spacing: 0.08em;
    margin-bottom: 5px;

    @media (--md-down) {
        font-size: 14px;
    }
}

@mixin form-control(
    $control-height: 48px,
    $control-height-small: 35px,
    $control-padding: 10px,
    $control-border: 1px
) {
    background-color: $white;
    border-color: $gray-200;
    border-radius: 0;
    border-style: solid;
    border-width: 0 0 1px;
    box-shadow: none;
    caret-color: $black;
    color: $black;
    display: block;
    filter: none;
    font-family: $font-family-theinhardt;
    font-size: 16px;
    font-weight: 400;
    @if ($feature-redesign) {
        font-family: $font-family-sans;
        font-weight: normal;
    }
    height: $control-height;
    letter-spacing: 0.08em;
    line-height: $control-height - 2 * $control-padding - $control-border;
    outline: 0;
    padding: $control-padding 0;
    width: 100%;

    @media (--md-down) {
        font-size: 14px;
    }

    @media (--sm) {
        height: $control-height-small;
        line-height: $control-height-small - 2 * $control-padding - $control-border;
    }
}

@mixin form-control--focus() {
    border-color: $black;
}

@mixin form-control--disabled() {
    background-color: $white;
    border-color: $gray-100;
    color: $gray-300;
}

@mixin form-text() {
    color: $gray-900;
    font-size: $font-size-secondary;
    font-weight: 300;

    @if ($feature-redesign) {
        font-weight: normal;
    }
    letter-spacing: 0.03em;
    line-height: 1.25;
    margin-top: 15px;
}

@mixin form-checkbox-label() {
    cursor: pointer;
    font-size: $font-size-secondary;
    font-weight: 300;

    @if ($feature-redesign) {
        font-weight: normal;
    }
    letter-spacing: 0.015em;
    line-height: 1.25;
    margin: 0;
    padding-left: 30px;
    position: relative;

    &::before {
        @include icons-styles();

        border: 1px solid $gray-200;
        border-radius: 0;
        content: "";
        height: 14px;
        left: 0;
        position: absolute;
        text-align: center;
        top: -2px;
        width: 14px;
    }
}

@mixin form-checkbox-label--checked() {
    &::before {
        border-color: $black;
        content: icons-char(checkbox);
    }
}

@mixin form-checkbox-label--disabled() {
    color: $gray-300;

    &::before {
        background-color: $gray-100;
        border-color: $gray-100;
        color: $gray-300;
    }
}
