.b-product-picture {
    background-color: rgba($black, 0.03);
    display: inline-block;
    font-size: 0;
    overflow: hidden;
    position: relative;
    vertical-align: top;
    width: 100%;

    &.m-order-2 {
        display: none;
    }

    // Spacer for standard tile
    &::before {
        content: "";
        float: left;
        padding-bottom: trim-float(percentage($picture-ratio));

        .search-container & {
            padding-bottom: trim-float(percentage($picture-search-ratio));
        }
    }

    // Spacer for doubled tile
    &::after {
        display: none;
        content: "";
        float: left;

        @each $key, $gutter in $grid-gutters {
            @media (--#{$key}) {
                padding-bottom: calc(#{trim-float(percentage($picture-ratio * 0.5))} - #{trim-float($picture-ratio * $gutter * 0.5)});
            }
        }

        .search-container & {
            @each $key, $gutter in $grid-gutters {
                @media (--#{$key}) {
                    padding-bottom: calc(#{trim-float(percentage($picture-search-ratio * 0.5))} - #{trim-float($picture-search-ratio * $gutter * 0.5)});
                }
            }
        }
    }

    &.m-doubled {
        &::before {
            display: none;
        }

        &::after {
            display: block;
        }
    }

    &__picture {
        height: 100%;
        left: 0;
        opacity: 1;
        position: absolute;
        top: 0;
        transition: opacity $transition-ease-short;
        width: 100%;

        .giftcert-image & {
            position: static;
        }
    }

    &__zoom-container {
        height: 100%;
        width: 100%;
        position: absolute;
        opacity: 0;
        transition: .3s opacity;

        &--show {
            opacity: 1;
        }

        img {
            height: 100%;
        }
    }

    &__zoom-grid {
        display: flex;
        flex-wrap: wrap;
    }

    &__zoom-cell {
        width: 100%;
        height: 100%;
    }

    &.m-widget-loading & {
        &__picture {
            opacity: 0;
            transition-duration: 0s;
        }

        &__image {
            opacity: 1;
        }
    }

    &__image {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    &.m-noimage & {
        &__picture {
            align-items: center;
            display: flex;
            justify-content: center;
        }

        &__image {
            max-width: 200px;
        }
    }

    &.giftcert-image {
        &::before {
            display: none;
        }
    }
}
