.modal {
    background: rgba($black, 0.5);
    display: inherit;
    font-size: $font-size-primary;
    padding: 10px 0;

    @media (--lg-up) {
        font-size: $font-size-secondary;
    }

    .close {
        @include icons-styles;

        background: none;
        border: 0;
        font-size: 10px;
        padding: 20px;
        z-index: 1;

        @if ($feature-redesign) {
            color: $hush-black;
        }
    }
}

.modal-dialog {
    @include make-container-paddings;

    align-items: center;
    box-sizing: content-box;
    display: flex;
    min-height: 100%;
    transform: none !important; // to override Bootstrap style
    transition: none !important; // to override Bootstrap style

    @media (--ie) {
        display: block;
        line-height: 90vh;
        line-height: calc(100vh - 20px);
    }

    .modal-button {
        display: none;
    }
}

.modal-size-guide {
    @media (--sm) {
        transform: translateY(100%);
        transition: transform $transition-ease;
    }
}

.size-guide-redesign {
    @media (--sm) {
        transform: translateY(0%);
        position: absolute;
        bottom: 0;
        width: 100%;
        min-height: 90svh;
    }

    @media (--sm-up) {
        max-height: 90vh;
    }

    @media (--md-up) {
        width: 680px;
        overflow-y: scroll;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @media (--lg-up) {
        width: 838px;
        overflow-y: scroll;
    }

    position: sticky;
    max-height: 90vh;
    visibility: visible;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    .modal-content {
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        flex-wrap: nowrap;
        flex-direction: column;

        @media (--sm) {
            min-height: 90svh;
            max-height: 90svh;
        }

        @media (--md-up) {
            max-height: 87vh;
            min-height: 87vh;
            padding: 0 40px;
        }

        &::-webkit-scrollbar,
        .modal-button {
            display: none;
        }

        .close {
            padding: 0px;
    
            @media (--md-up) {
                padding: 20px;
            }
        }

        background: $hush-white;
    }

    .modal-header {
        top: 0;
        position: sticky;
        background: $hush-white;
        z-index: 1;
        width: 100%;
        height: max-content;
        display: flex;
        align-items: baseline;
        padding: 25px 0px 25px 20px;

        @media (--sm) {
            margin-right: 0px;
            margin-left: 0px;

            &::after {
                content: "";
                display: block;
                width: 90%;
                height: 1px;
                background: $light-grey;
                position: absolute;
                bottom: 0;
            }
        }

        @media (--md-up) {
            padding: 25px 0px;

            &::after {
                content: "";
                display: block;
                width: 100%;
                height: 1px;
                background: $light-grey;
                position: absolute;
                bottom: 0;
                right: 0px;
            }
        }

        .close {
            order: 2;
            padding-right: 0 !important;
            margin: 0;
            margin-right: 20px;

            @media (--md-up) {
                margin-right: 0;
             }
        }
    }

    .modal-title {
        @include n3;
        position: sticky;
        top: 0;
        z-index: 2;
        margin: 0;
        order: 1;
        width: auto;

        @media (--md) {
            margin-bottom: 0;
            margin-top: 0;
            font-weight: 450;
        }
    }

    .modal-body {
        @media (--sm) {
            padding-bottom: 0;
        }

        @media (--md-up) {
            padding-left: 0 !important;
            padding-right: 0 !important;
            padding-bottom: 0px;
        }
    }

    background-color: $hush-white;

    &.shoes-size-guide {
        min-height: 50vh;
        max-height: 50vh;

        @media (--sm) {
            min-height: 40vh;
            max-height: 40vh;
        }

        .modal-content {
            @media (--sm) {
                min-height: 40vh;
                max-height: 40vh;
            }

            @media (--md) {
                padding-bottom: 25px;
            }

            @media (--md-up) {
                min-height: 0;
            }

            .modal-header {
                @media (--md) {
                    padding: 40px 0px 24px;
                }

                &::after {
                    width: 100%;
                }
            }
        }
    }

    &.clothing-numeric-size-guide {
        min-height: 90svh;
        max-height: 90svh;

        .modal-content {
            .modal-body {
                @media (--md-up) {
                    margin-top: 12px;
                }
            }
        }
    }         
}

.size-guide-redesign-close {
    @media (--sm) {
        transform: translateY(100%);
        position: absolute;
        bottom: 0;
        width: 100%;
    }
}

.modal-content {
    background-clip: inherit;
    flex-flow: row wrap;

    @media (--ie) {
        display: inline-flex;
        line-height: $line-height-base;
        vertical-align: middle;
    }
}

.modal-header {
    flex-wrap: wrap;
    width: 100%;
}

.modal-title,
.modal-subtitle {
    font-family: $font-family-theinhardt;
    font-size: 26px;
    font-weight: 500;
    @if ($feature-redesign) {
        font-family: $font-family-sans;
        font-weight: normal;
    }
    letter-spacing: 0.03em;
    line-height: 1.31;
    margin: 0 24px 30px;
    order: 1;
    width: 100%;

    @media (--sm) {
        margin-left: 20px;
        margin-right: 20px;
    }
}

.recomendation-title {
    @extend .modal-title;
    display: none;
}

.modal-body {
    width: 100%;

    @media (--sm) {
        padding-left: 20px;
        padding-right: 20px;
    }

    .pagination {
        display: none;
    }
}

.modal-footer {
    border-top: 1px solid $gray-100;
    padding: 19px;
    width: 100%;

    @media (--sm) {
        padding: 15px;
    }

    .modal-body + & {
        margin-top: -30px;
    }

    .btn {
        min-width: 100px;
    }
}
