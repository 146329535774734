@import "./next/_next-header";

@if ($feature-redesign) {
    // homepage specific nav styles
    [data-pageid*="homepage-template"] .l-header {
        &__content {
            height: auto;
        }

        &__logo svg {
            width: 93.5px;
            height: 25px;

            @media (map-get($next-header-breakpoints, xs)) {
                width: 140px;
                height: 37px;
            }

            @media (map-get($next-header-breakpoints, sm)) {
                width: 156px;
                height: 41px;
            }

            @media (map-get($next-header-breakpoints, xl)) {
                width: 218px;
                height: 58px;
            }
        }

        &__navigation--wide+.l-header__logo svg {
            max-width: auto; // limit logo size when there are 6+ nav categories
            max-height: auto;
        }

        &__container {
            @media (map-get($next-header-breakpoints, xl)) {
                padding: 32px 40px 16px 40px;
            }
        }

        &__modal-background {
            top: 64px;

            @media (map-get($next-header-breakpoints, xs)) {
                top: 72px;
            }

            @media (map-get($next-header-breakpoints, sm)) {
                top: 80px;
            }

            @media (map-get($next-header-breakpoints, xl)) {
                top: 112px;
            }
        }
    }
}

.l-header {
    position: relative; // fallback for browsers that do not support sticky position
    top: 0;
    z-index: $z-header;

    &[data-initialized] {
        position: sticky;
    }

    &__content {
        background-color: $white;
        color: $black;
        position: relative;

        @if ($feature-redesign) {
            background-color: $hush-white;
            transition: $transition-ease-logo;
        }
    }

    &__modal-background {
        @include modal-background;

        .opened-minicart & {
            opacity: 0.5;
            pointer-events: initial;
            transition-delay: 0s, 0s;
            z-index: $z-minicart - 1;
        }

        .opened-reviews & {
            opacity: 0.5;
            pointer-events: initial;
            transition-delay: 0.2s, 0s;
            z-index: $z-reviews - 1;
        }

        .opened-navigation & {
            @media (--md-down) {
                opacity: 0.5;
                pointer-events: initial;
                transition-delay: 0s, 0s;
                z-index: $z-hamburger - 1;
            }
        }
    }

    @if ($feature-redesign) {
        &__modal-minicart {
            top: 0 !important;
            height: 100%;
        }
    }

    &__container {
        align-items: flex-end;
        display: flex;
        justify-content: space-between;

        @if $feature-redesign {
            max-width: none;
            padding: 16px;
            align-items: flex-start;
            position: relative;
            z-index: 3;
            background-color: inherit;

            .opened-minicart & {
                z-index: unset;
            }

            @media (map-get($next-header-breakpoints, xl)) {
                padding: 16px 40px;
            }
        }

        @else {
            min-height: 64px;

            @media (--md) {
                padding-bottom: 29px;
                padding-top: 29px;
            }

            @media (--sm-up) {
                padding-bottom: 17px;
                padding-top: 17px;
            }

            @media (--md-down) {
                align-items: center;
            }

            @media (--lg-up) {
                position: relative;
            }

            @media (--lg) {
                padding-bottom: 30px;
                padding-top: 30px;
            }

            @media (--xl) {
                padding-bottom: 35px;
                padding-top: 35px;
            }

            @media (--xxl) {
                padding-bottom: 42px;
                padding-top: 42px;
            }

            @each $breakpoint,
            $gutter in $header-gutters {
                @media (--#{$breakpoint}) {
                    padding-left: $gutter;
                    padding-right: $gutter;
                }
            }
        }
    }

    &__logo {

        @if ($feature-redesign) {
            pointer-events: none;
            order: 2;

            a {
                pointer-events: all;
            }

            svg {
                transition: 250ms ease-in;
                width: 93.5px;
                height: 25px;

                @media (map-get($next-header-breakpoints, xl)) {
                    width: 125px;
                    height: 33px;
                }
            }

            .opened-navigation & {
                visibility: hidden;

                @media (map-get($next-header-breakpoints, lg)) {
                    visibility: visible;
                }
            }


        }

        @else {
            order: 1;
            margin-right: $header-logo-gutter;

            @media (--md-down) {
                margin: 0 auto;
            }

            svg {
                height: 40px;
                vertical-align: top;
                width: map-get($header-logo-width, xl);

                @media (--sm) {
                    height: 30px;
                    width: map-get($header-logo-width, sm);
                }

                @media (--md) {
                    height: 41px;
                    width: map-get($header-logo-width, md);
                }

                @media (--xxl) {
                    height: 52px;
                    width: map-get($header-logo-width, xxl);
                }
            }
        }
    }

    &__navigation {
        height: 24px;
        display: flex;

        .b-navigation {
            display: none;
        }

        .b-hamburger-toggler {
            display: block;
        }

        @if ($feature-redesign) {
            order: 1;
            align-items: center;

            .opened-navigation & {
                .btn-open-nav {
                    display: none;
                }
            }

            @media (map-get($next-header-breakpoints, desktopNav)) {
                .b-navigation {
                    display: flex;
                }
                .b-hamburger,
                .btn-clr {
                    display: none;
                }
            }
        }

        @else {
            order: 2;

            @media (--lg-up) {
                margin-right: auto;

                .b-navigation {
                    display: flex;
                }

                .b-hamburger-toggler,
                .b-hamburger {
                    display: none;
                }
            }

            @media (--md-down) {
                order: -1;
            }
        }
    }

    &__utilities {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        order: 3;

        @if ($feature-redesign) {
            justify-content: flex-start;

            z-index: 3;

            @media (map-get($next-header-breakpoints, xl)) {
                z-index: inherit;
            }
        }
    }

    &__navigation,
    &__utilities {
        @if ($feature-redesign) {
            min-width: 90px;
            height: 24px;
            flex: 1;
        }

        @else {
            @media (--md) {
                min-width: 300px;
            }

            @media (--sm) {
                min-width: 110px;
            }
        }
    }

    &__search {
        left: 0;
        margin: 0 auto;
        padding: 0 map-get($container-gutters, md);
        position: absolute;
        top: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        background: none;

        .search-container {
            @media (--sm) {
                display: none;
            }
        }

        @media (--md-up) {
            height: auto !important;
            transition: opacity $transition-ease-short;

            &.hide {
                opacity: 0;
                pointer-events: none;
            }
        }
    }

    &__search-toggler {
        @media (--sm) {
            margin-right: 22px;
            order: -1;
        }
    }
}

.l-header-messages {
    background-color: $white;
    // position: relative;
    z-index: $z-header;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}

@if ($feature-redesign) {
    $transparent-header-selector: "html:not(.header-is-sticky):not(.header-is-hovered):not(.opened-search)";
    $is-white: true !default;

    .l-header {
        height: 0;
    }

    div:not([data-pageid*="homepage-template"]) .l-header {
        height: 69px;

        @media (--sm) {
            height: 61px;
        }
    }

    @media (--lg-up) {
        #{$transparent-header-selector} {
            .l-header {
                &__content {
                    background-color: transparent;
                }
            }
        }

        html:not(.header-is-sticky) {
            .l-header__content {
                transition: background-color $transition-ease-logo;
            }
        }

        #{$transparent-header-selector} {
            .l-header__search,
            .b-navigation-flyout {
                opacity: 0;
                transition: none;
            }
        }
    }

    @media (--md-down) {
        $transparent-header-selector: 'html:not(.header-is-sticky):not(.opened-search):not(.opened-navigation)';
        #{$transparent-header-selector} {
            .l-header {
                &__content {
                    background-color: transparent;
                }
            }

            html:not(.header-is-sticky) {
                .l-header__content {
                    transition: background-color $transition-ease-logo;
                }
            }
        }

        .l-header {
            &__search {
                transition: none;
            }
        }
    }
}

@if ($feature-redesign) {
    // logo sizes on any other page, on scroll and landing
    .l-header__logo svg {
        width: 93.4px;
        height: 24.8px;
    }

    @media screen and (min-width: 1346px) {
        .l-header__logo svg {
            width: 124.5px;
            height: 33px;
        }
    }

    // logo sizes on homepage, on scroll and landing
    [data-pageid*="homepage-template"] {
        @media screen and (max-width: 374px) {
            .l-header__logo svg {
                width: 93.4px;
                height: 24.8px;
            }
        }

        @media screen and (min-width: 375px) {
            .l-header__logo svg {
                width: 140px;
                height: 37.2px;
            }
        }

        @media screen and (min-width: 404px) {
            [data-pageid="null"] {
                .l-header__logo svg {
                    width: 93.4px;
                    height: 24.8px;
                }
            }

            .l-header__logo svg {
                width: 155.7px;
                height: 41.4px;
            }
        }

        @media screen and (min-width: 1280px) {
            .l-header__logo svg {
                width: 218px;
                height: 58px;
            }

            // limit logo size on homepage when there are 6+ nav categories, on scroll and landing
            .l-header__navigation--wide+.l-header__logo svg {
                width: 186.8px;
                height: 49.6px;
            }
        }

        @media screen and (min-width: 1400px) {
            .l-header__logo svg {
                width: 187px;
                height: 49.6px;
            }
        }
    }

}

@mixin ms-sticky-header {
    .l-header {
        @media (--lg) {
            height: 100px;
        }

        @media (--xl) {
            height: 110px;
        }

        @media (--xxl) {
            height: 136px;
        }

        .header-is-sticky &__content {
            left: 0;
            position: fixed;
            top: 0;
            width: 100%;
        }
    }
}

// Sticky header for IE

@media (--ie) {
    @include ms-sticky-header;
}

// Sticky header for Edge

@supports (-ms-accelerator:true) or (-ms-ime-align:auto) {
    @include ms-sticky-header;
}
