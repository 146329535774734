.b-popout.b-popout--open {
    .b-popout__content.b-reviews {
        padding: 24px;
        width: 100%;
    }

    .hide {
        display: none;
    }
}

.b-reviews-wrapper {
    &.hide {
        display: none;
    }
}

.b-reviews {
    overflow-y: scroll;

    .b-loader {
        z-index: 10;
    }

    >.b-review:nth-child(odd) {
        background: #fbf8f2;
    }

    &__close {
        background: none;
        border: none;
        display: block;
        position: absolute;
        right: 3.5rem;
        top: 3rem;
        font-size: 1.5rem;
    }

    &__heading {
        font-size: 2rem;
        font-weight: 400;
        margin: 0;
    }

    &__sub-heading {
        font-weight: 400;
        font-size: 1.8rem;
    }

    &__star {
        @include icons(star);
    }

    &__sortBy {
        border: 0;
        cursor: pointer;
    }

    &__star-stroke {
        @include icons(star-stroke);
        fill: $gray-100;
    }

    &__submitted-days {
        color: $gray-200;
        white-space: nowrap;
        font-size: 1rem;
        font-weight: 300;
        text-align: right;
    }

    &__review-top {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    &__overall-container {
        display: flex;
        justify-content: space-between;

    }

    &__reviews-count {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0.5rem;
        align-items: flex-end;
    }

    &__total-reviews {
        font-size: 0.875rem;
        font-weight: 300;
        color: $gray-300;
    }

    &__sort-title {
        text-transform: capitalize;
        font-weight: 300;
        margin: 0;
        top: 50%;
    }

    &__newest {
        display: flex;
        justify-content: space-between;
    }


    &__pages {
        font-size: 1.125rem;
        color: $gray-200;
        display: flex;
        justify-content: center;
    }

    .btn {
        width: 100%;
    }

    &__pagination-container,
    &__page-count {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__page-count {
        font-size: 0.75rem;
        gap: 0.25rem;
        padding: 0 0 0.75rem 0;
    }

    &__pagination-number,
    &__pagination-button {
        font-size: 1.1rem;
        background-color: transparent;
        border: none;
        height: 2.5rem;
        width: 2.5rem;
        transition: 400ms;
    }

    &__pagination-number:hover,
    &__pagination-button:not(.disabled):hover {
        background-color: $gray-200;
    }

    &__empty {
        > * {
            margin: 1rem 0;
        }
    }
}

.b-review {
    margin: 1rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    ;
    list-style-type: none;
    padding: 1rem 0 1.875rem 0;

    &__container {
        padding: 0;

        &.list {
            display: flex;
            margin: 1rem 0;
            padding: 0;
        }
    }

    &__rating {
        display: flex;
        gap: 0.5rem;
        padding: 0.5rem 0;
        font-size: 1.35rem;
    }

    &__text {
        font-size: 1.2rem;
        font-weight: 300;
        color: $gray-300;
        padding: 0.5rem 0;
        margin-bottom: 0;
    }

    &__user-name {
        display: block;
        font-size: 1.4rem;
        line-height: 1.5rem;
        padding-bottom: 1rem;
    }

    &__user-stats {
        display: grid;
        font-size: 1.2rem;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        width: 100%;

        >* {
            min-width: 6rem;
            padding-bottom: 1rem;

            &:nth-child(even) {
                font-weight: bold;
            }
        }
    }
}

.b-fit {
    margin: 1rem 0;
    display: flex;
    gap: 5px;
    justify-content: space-between;

    &__bar {
        display: block;
        height: 2px;
        background: $gray-200;
        width: 20%;

        &.current {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                display: block;
                background: $black;
                width: 10px;
                height: 10px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 7.5px;
            }

            .label {
                display: block;
                position: absolute;
                width: 100%;
                text-align: center;
                top: -1.8rem;
                font-size: 0.9rem;
                font-weight: 400;
                white-space: nowrap;
            }
        }
    }
}

.b-pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 1rem;
    flex-wrap: wrap;

    &__button:hover {
        background-color: $gray-100;
    }

    &__button {
        cursor: pointer;
        color: $gray-400;
        font-family: $font-family-theinhardt;
        font-size: 1.25rem;
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        transition: 400ms;

        &--active,
        &[iscurrentpage="true"] {
            color: $black;
            text-decoration: underline;
        }

        &--previous {
            @include icons(arrow-left);
        }

        &--next {
            @include icons(arrow-right);
        }
    }
}

.b-separator {
    border-top: 1px solid $black;
    width: 100%;

    &.b-separator--muted {
        border-top-color: $gray-200;
    }

    &.b-separator--bold {
        border-top-width: 2px;
    }
}