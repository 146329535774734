@if ($feature-redesign) {
    .b-product__variations {
        font-family: $font-family-sans;
    }
}

.b-product-variation {
    & + & {
        margin-top: 20px;
    }

    &__title {
        display: none;
        margin-bottom: 10px;
    }

    &__content {
        font-size: 0;
        margin: 0 -10px -10px 0;

        .l-product--gift-certificate & {
            margin: 0 -20px -20px 0;
        }
    }

    &__button {
        background: none;
        border: 1px solid $gray-100;
        color: $black;
        font-size: $font-size-primary;
        line-height: 14px;
        margin: 0 10px 10px 0;
        min-width: 40px;
        outline: 0;
        padding: 8px 7px;
        text-align: center;

        @if ($feature-redesign) {
            @include n10;
        }

        &:last-child {
            margin-right: 0;
        }

        @media (max-width: 1441px) and (min-width: 1200px) {
            min-width: 35px;
            padding: 7px 0;
            margin-right: 8px;
        }

        @media (--md) {
            min-width: 30px;
            padding: 6px 0;
            margin-right: 6px;
        }

        @media (max-width: 767px) and (min-width: 364px) {
            min-width: 35px;
            padding: 7px 0;
            margin-right: 9px;
        }

        @media (max-width: 363px) and (min-width: 324px) {
            min-width: 30px;
            padding: 7px 0;
            margin-right: 9px;
        }

        @media (max-width: 323px) and (min-width: 0) {
            margin-right: 7px;
            min-width: 31px;
            padding: 7px 0;
            margin-right: 7px;
        }

        &.m-widget-loading {
            animation: loading-bg 1s linear infinite;
        }

        &.unselectable {
            color: $gray-1000;
        }

        &.unselectable > .b-product-variation__value.pdp_bell:before {
           content: ' ';
            position: absolute;
            right: -20px;
            background-repeat: no-repeat;
            top: -16px;
            width: 16px;
            height: 16px;
            font-size: 3rem;
        }

        &.visible {
            display: block;

        }

        &.selected {
            border-color: $black;
        }

        &.dual {
            min-width: 40px;
            padding: 8px 0px;
            font-family: $font-family-sans;
            font-size: 12px;
            line-height: 16.25px;
            font-weight: 500;

            .b-product-variation__value {
                padding-bottom: 2px;
            }

            .alpha-numeric {
                line-height: 18px;
                font-size: 10px;
                color: $gray-1000;
            }

            &.unselectable .alpha-numeric {
                text-decoration: line-through;
            }

            &.wide {
                min-width: 60px;
            }

            @media (--md-up) {
                font-size: 10.8px;
                line-height: 14.4px;
                padding: 8px 0px;
                min-width: 36px;

                &.wide {
                    min-width: 52px;
                }
            }

            @media (--lg-up) {
                min-width: 48px;
                padding: 8px 0px;
                font-size: 13.5px;
                line-height: 18px;

                &.wide {
                    min-width: 60px;
                }
            }
        }
    }

    &__swatch {
        background: none;
        border: 1px solid transparent;
        border-radius: 100%;
        font-size: 0;
        height: 30px;
        margin: 0 10px 10px 0;
        padding: 3px;
        width: 30px;

        .non-touch &:not(.selected):hover {
            border-color: $gray-100;
        }

        &.m-widget-loading {
            animation: loading-bg 1s linear infinite;
        }

        &.unselectable.selected {
            opacity: 0.5;
        }

        &.selected {
            border-color: $black;
        }
    }

    &__color {
        background-color: rgba($black, 0.05);
        background-size: cover;
        border-radius: 100%;
        box-shadow: 0 0 0 1px rgba($black, 0.05) inset;
        display: block;
        height: 100%;
        width: 100%;

        .m-widget-loading > & {
            animation: loading-opacity 1s 0.5s linear infinite;
        }

        &+span.b-product-variation__value {
            position: fixed;
        }
    }

    &__value {
        display: inline-block;
        white-space: nowrap;

        .unselectable > & {
            position: relative;

            &::after {
                background: $gray-1000;
                content: "";
                height: 1px;
                left: -2px;
                position: absolute;
                right: -2px;
                top: 45%;
            }
        }
    }

    &__title-label {
        color: $gray-300;

        &::after {
            content: ":";
        }
    }

    &--color & {
        &__title {
            display: block;
        }

        &__title-label {
            display: none;
        }
    }

    &--color + & {
        margin-top: 23px;
    }

    &--size ~ & {
        order: 1;
    }

    &[class*="--width"] &,
    &[class*="--length"] & {
        &__button {
            min-width: 65px;
        }
    }
}

.l-product__details {

    .b-product-variation {

        &__button {

            &.unselectable {
                color: $gray-1000;
                position: relative;
    
                &:after {
                    content: '';
                    position: absolute;
                    background-image: url('../images/bell-desktop.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    width: 16px;
                    height: 18px;
                    top: -9px;
                    right: -8px;
                    filter: grayscale(100%) brightness(200%);
                    z-index: 1;
                }
    
                &:before {
                    content: '';
                    position: absolute;
                    width: 16px;
                    height: 18px;
                    top: -9px;
                    right: -8px;
                    background-color: $hush-white;
                    z-index: 0;
                }
    
                @media (--md) {
    
                    &:after {
                        content: '';
                        position: absolute;
                        background-image: url('../images/bell-tablet.svg');
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center;
                        width: 14px;
                        height: 16px;
                        top: -8px;
                        right: -7px;
                    }
    
                    &:before {
                        content: '';
                        position: absolute;
                        width: 14px;
                        height: 16px;
                        top: -8px;
                        right: -7px;
                        background-color: $hush-white;
                        z-index: 0;
                    }
                }
    
            }

            &.unselectable.selected {
                border-color: $earthen-brown;
    
                &:after {
                    filter: none;
                }
    
            }
        }
    }
}