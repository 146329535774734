.b-back-to-top {
    bottom: 0;
    height: 0;
    position: relative;
    position: sticky;
    right: 0;

    @if $feature-redesign {
        order: 2;

        &__button {
            bottom: 60px;
        }
    } @else {
        &__button {
            bottom: 30px;
        }    
    }

    &__button {        
        color: $back-to-top-color;
        font-size: $font-size-secondary;
        line-height: 1;
        min-width: 60px;
        position: absolute;
        right: 30px;
        text-align: center;

        @media (--sm) {
            bottom: 15px;
            right: 10px;
        }

        @media (--ie) {
            position: fixed;

            &.m-bottom {
                position: absolute;
            }
        }

        &::before {
            display: block;
            font-size: 14px;
            margin-bottom: 4px;
        }
    }
}
