.text-normal {
    text-transform: none !important;
}

@include generate-classes(
    text,
    color,
    (
        'black': $black,
        'white': $white
    )
);
