@each $key, $width in $grid-breakpoints {
    $index: index(($grid-breakpoints), ($key $width));
    $width-prev: map-get-prev($grid-breakpoints, $key, 0);
    $width-next: map-get-next($grid-breakpoints, $key, false);

    @if ($width-next and strip-unit($width-prev) > 0) {
        @custom-media --#{$key} (min-width: #{$width}) and (max-width: #{$width-next - 1});
        @custom-media --#{$key}-down (max-width: #{$width-next - 1});
        @custom-media --#{$key}-up (min-width: #{$width});
    } @else if ($width-next) {
        @custom-media --#{$key} (max-width: #{$width-next - 1});
    } @else if (strip-unit($width) > 0) {
        @custom-media --#{$key} (min-width: #{$width});
        @custom-media --#{$key}-up (min-width: #{$width});
    }
}

@custom-media --xxl (min-width: 1800px);
@custom-media --xxl-up (min-width: 1800px);
@custom-media --xl-all (min-width: 1300px) and (max-width: 1440px);
@custom-media --xl-all-sm (min-width: 1200px) and (max-width: 1299px));
@custom-media --xl-upp (min-width: 1600) and (max-width: 1799px);

@custom-media --landscape (orientation: landscape);
@custom-media --portrait (orientation: portrait);

@custom-media --ie ((-ms-high-contrast: active), (-ms-high-contrast: none));
