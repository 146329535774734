/*md

# General Icons

We use gulp module [gulp-iconfont](https://github.com/nfroidure/gulp-iconfont) for SVG icons to generate a font.

## How it works

**For generate iconfont, simply copy each SVG icon into a folder:**

`cartridges/app_storefront_custom/cartridge/static/default/iconfonts/sources`

**After that, run following command**

`npm run compile:iconfont`

As soon as the generation of your icons is completed, you will see that the font you generated appears in

`cartridges/app_storefront_custom/cartridge/static/default/iconfonts/`,

## Examples of using

**Usage as a mixin:**
```scss
    .b-block-name {
        @include icons-styles(chevron-left); // chevron-left - icon name
    }
```

**or HTML (open code):**

```html_example
    <!-- i-icon-name -->
    <div class="b-block-name">
        <div class="b-block-name__icon i-chevron-left"></div>
    </div>
```

## Icon Library

```html_example
<div class="example-icons">
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-applepay"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(applepay);
                </div>
                <div class="example-icons__name">i-applepay</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-arrow-down"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(arrow-down);
                </div>
                <div class="example-icons__name">i-arrow-down</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-arrow-left"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(arrow-left);
                </div>
                <div class="example-icons__name">i-arrow-left</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-arrow-right"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(arrow-right);
                </div>
                <div class="example-icons__name">i-arrow-right</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-arrow-up"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(arrow-up);
                </div>
                <div class="example-icons__name">i-arrow-up</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-arrow"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(arrow);
                </div>
                <div class="example-icons__name">i-arrow</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-bag"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(bag);
                </div>
                <div class="example-icons__name">i-bag</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-checkbox"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(checkbox);
                </div>
                <div class="example-icons__name">i-checkbox</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-close-bold"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(close-bold);
                </div>
                <div class="example-icons__name">i-close-bold</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-close-thin"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(close-thin);
                </div>
                <div class="example-icons__name">i-close-thin</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-edit"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(edit);
                </div>
                <div class="example-icons__name">i-edit</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-email"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(email);
                </div>
                <div class="example-icons__name">i-email</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-facebook"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(facebook);
                </div>
                <div class="example-icons__name">i-facebook</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-filters"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(filters);
                </div>
                <div class="example-icons__name">i-filters</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-globe"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(globe);
                </div>
                <div class="example-icons__name">i-globe</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-hamburger-bold"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(hamburger-bold);
                </div>
                <div class="example-icons__name">i-hamburger-bold</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-hamburger"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(hamburger);
                </div>
                <div class="example-icons__name">i-hamburger</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-instagram"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(instagram);
                </div>
                <div class="example-icons__name">i-instagram</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-lock"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(lock);
                </div>
                <div class="example-icons__name">i-lock</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-mastercard"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(mastercard);
                </div>
                <div class="example-icons__name">i-mastercard</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-paypal"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(paypal);
                </div>
                <div class="example-icons__name">i-paypal</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-pinterest"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(pinterest);
                </div>
                <div class="example-icons__name">i-pinterest</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-point"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(point);
                </div>
                <div class="example-icons__name">i-point</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-search"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(search);
                </div>
                <div class="example-icons__name">i-search</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-star-stroke"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(star-stroke);
                </div>
                <div class="example-icons__name">i-star-stroke</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-star"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(star);
                </div>
                <div class="example-icons__name">i-star</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-success"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(success);
                </div>
                <div class="example-icons__name">i-success</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-twitter"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(twitter);
                </div>
                <div class="example-icons__name">i-twitter</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-close"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(close);
                </div>
                <div class="example-icons__name">i-close</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-user-in"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(user-in);
                </div>
                <div class="example-icons__name">i-user-in</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-user"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(user);
                </div>
                <div class="example-icons__name">i-user</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-visa"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(visa);
                </div>
                <div class="example-icons__name">i-visa</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-warning"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(warning);
                </div>
                <div class="example-icons__name">i-warning</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-whatsapp"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(whatsapp);
                </div>
                <div class="example-icons__name">i-whatsapp</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-wishlist-filled"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(wishlist-filled);
                </div>
                <div class="example-icons__name">i-wishlist-filled</div>
            </div>
        </div>
        <div class="example-icons__item-wrapper">
            <div class="example-icons__item">
                <div class="example-icons__icon"><div class="i-wishlist"></div></div>
                <div class="example-icons__mixin">
                    @include icons-styles-(wishlist);
                </div>
                <div class="example-icons__name">i-wishlist</div>
            </div>
        </div>
</div>
```

*/

[class*='i-']::before,
[class*='i-']::after {
    @include icons-styles;
}

.i-applepay::before,
.i-after-applepay::after {
    content: icons-char(applepay);
}

.i-arrow-down::before,
.i-after-arrow-down::after {
    content: icons-char(arrow-down);
}

.i-arrow-left::before,
.i-after-arrow-left::after {
    content: icons-char(arrow-left);
}

.i-arrow-right::before,
.i-after-arrow-right::after {
    content: icons-char(arrow-right);
}

.i-arrow-up::before,
.i-after-arrow-up::after {
    content: icons-char(arrow-up);
}

.i-arrow::before,
.i-after-arrow::after {
    content: icons-char(arrow);
}

.i-bag::before,
.i-after-bag::after {
    content: icons-char(bag);
}

.i-checkbox::before,
.i-after-checkbox::after {
    content: icons-char(checkbox);
}

.i-close-bold::before,
.i-after-close-bold::after {
    content: icons-char(close-bold);
}

.i-close-thin::before,
.i-after-close-thin::after {
    content: icons-char(close-thin);
}

.i-edit::before,
.i-after-edit::after {
    content: icons-char(edit);
}

.i-email::before,
.i-after-email::after {
    content: icons-char(email);
}

.i-facebook::before,
.i-after-facebook::after {
    content: icons-char(facebook);
}

.i-filters::before,
.i-after-filters::after {
    content: icons-char(filters);
}

.i-globe::before,
.i-after-globe::after {
    content: icons-char(globe);
}

.i-hamburger-bold::before,
.i-after-hamburger-bold::after {
    content: icons-char(hamburger-bold);
}

.i-hamburger::before,
.i-after-hamburger::after {
    content: icons-char(hamburger);
}

.i-instagram::before,
.i-after-instagram::after {
    content: icons-char(instagram);
}

.i-lock::before,
.i-after-lock::after {
    content: icons-char(lock);
}

.i-mastercard::before,
.i-after-mastercard::after {
    content: icons-char(mastercard);
}

.i-paypal::before,
.i-after-paypal::after {
    content: icons-char(paypal);
}

.i-pinterest::before,
.i-after-pinterest::after {
    content: icons-char(pinterest);
}

.i-point::before,
.i-after-point::after {
    content: icons-char(point);
}

.i-search::before,
.i-after-search::after {
    content: icons-char(search);
}

.i-star-stroke::before,
.i-after-star-stroke::after {
    content: icons-char(star-stroke);
}

.i-star::before,
.i-after-star::after {
    content: icons-char(star);
}

.i-success::before,
.i-after-success::after {
    content: icons-char(success);
}

.i-twitter::before,
.i-after-twitter::after {
    content: icons-char(twitter);
}

.i-close::before,
.i-after-close::after {
    content: icons-char(close);
}

.i-user-in::before,
.i-after-user-in::after {
    content: icons-char(user-in);
}

.i-user::before,
.i-after-user::after {
    content: icons-char(user);
}

.i-visa::before,
.i-after-visa::after {
    content: icons-char(visa);
}

.i-warning::before,
.i-after-warning::after {
    content: icons-char(warning);
}

.i-whatsapp::before,
.i-after-whatsapp::after {
    content: icons-char(whatsapp);
}

.i-wishlist-filled::before,
.i-after-wishlist-filled::after {
    content: icons-char(wishlist-filled);
}

.i-wishlist::before,
.i-after-wishlist::after {
    content: icons-char(wishlist);
}
