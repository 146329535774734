.b-hamburger {
    background-color: $white;
    bottom: 0;
    color: $black;
    height: 0;
    left: -100%;
    line-height: 1;
    list-style: none;
    margin: 0;
    max-width: $navigation-width-max;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0 10px;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: left $transition-ease 0s, height $transition-ease 1s;
    z-index: $z-hamburger;

    @if ($feature-redesign) {
        padding-left: 16px;
        display: flex;
        flex-direction: column;
        background-color: $hush-white;

        .b-hamburger__search-button {
            font-size: 16px;
        }

        .b-hamburger__arrow {
            height: 48px;

            &::before {
                display: none;
            }
        }

        width: calc(100% - 56px);

        &.show-next {
            >.b-hamburger__item:not(.show-next) {
                display: none;
            }

            .b-hamburger__arrow {
                &::before {
                    display: block;
                }
            }
        }

        .b-country-selector {
            margin-top: auto;
            padding-left: 4px;

            @media (map-get($next-header-breakpoints, lg)) {
                max-width: 268px;
            }

            @media (map-get($next-header-breakpoints, tablet)) {
                max-width: 388px;
            }
        }
    }

    @else {
        width: $navigation-width;

        @media (--md) {
            padding-left: map-get($container-gutters, md);
            padding-right: map-get($container-gutters, md);

            .l-header__logo {
                display: none;
            }
        }
    }

    &.search-toggled {
        overflow: hidden;

        .b-hamburger__item--search {
            height: 100%;
        }

        .generalSuggestions,
        .b-hamburger__search-button {
            display: block;
        }
    }

    .opened-navigation & {
        height: 100%;
        left: 0;
        pointer-events: initial;
        transition: left $transition-ease;
    }

    &::after {
        content: "";
        display: block;

        @if $feature-redesign {
            height: 16px;
        }

        @else {
            height: 50px;
        }
    }

    &__close {
        @if ($feature-redesign) {
            padding: 16px 16px 16px 0;
            margin-bottom: 32px;

            .l-header__logo {
                display: none;
            }

        }

        @else {
            padding: 30px 20px 20px;
            padding-bottom: #{$search-gutter * 0.2};

            @media (--md-down) {
                align-items: center;
            }

            @media (--md) {
                padding: 19px 0 0;
                position: relative;
            }
        }


        display: inline-flex;
        justify-content: space-between;
        width: 100%;
    }

    &__search-button {
        color: $black;

        @if $feature-redesign {
            display: none;
            .opened-navigation & {
                display: block;

                @media (map-get($next-header-breakpoints, desktopNav)) {
                    display: none;
                }
            }
            z-index: 4;
        } @else {            
            position: absolute;
            right: 20px;
            top: 38px;
            
            @media (--md) {
                right: unset;
            }
        }
    }

    &__close-button {
        @if ($feature-redesign) {
            display: none;
        } @else {
            @media (--md) {
                display: none;
            }
        }
    }

    &__close-button,
    &__search-button {
        font-size: $font-size-h4;
        margin: -10px;
        padding: 10px;

        @media (--md) {
            position: absolute;

            @if not $feature-redesign {
                top: 40px;
            }
        }
    }

    @if ($feature-redesign) {
        &__search-button {
            margin: 0;
            padding: 0;
            width: 1.846rem;
            height: 1.846rem;
        }
    }

    &__item {

        @if ($feature-redesign) {
            &.show-next, &.show-next[data-headernavelement="SALE"] {
                .b-hamburger__link {
                    margin-left: 24px;
                    margin-top: -0.1rem;
                    z-index: 1;
                    color: $earthen-brown;
                    @include n11;

                    @media (min-width: 768px) {
                        margin-top: 0;
                    }
                }
            }

            &:not(:first-child, :nth-child(2)) {
                height: 40px;
            }

            .b-header-icons__button--close {
                display: none;
            }

            .search-mode &:not(&--search) {
                display: none;
            }

            .search-mode #page &.b-hamburger__item--search {
                display: block;
            }

            &[data-headernavelement="SALE"] {
                .b-hamburger__link {
                    color: #E40000;

                    &:active {
                        color: $earthen-brown;
                    }
                }
            }
        }

        @else {
            padding-right: 20px;
            padding-left: #{$search-gutter * 4 * 0.2};

            @media (--md) {
                padding-left: 40px;
                padding-right: 65px;
            }

            &--search {
                display: none;

                @media (--sm) {
                    display: block;
                }

                padding-left: #{$search-gutter * 0.3};

                .b-header-icons__button--close,
                .generalSuggestions {
                    display: none;
                    padding-bottom: 0;
                }

                .b-header-search {
                    padding: #{$search-gutter * 2 * 0.2};

                    @media (--md) {
                        padding-top: #{$search-gutter * 3 * 0.5};
                    }
                }
            }
        }

        &--start {
            border-top: 1px solid $gray-100;
            margin-top: 3px;
            padding-top: 3px;

            @media (--md) {
                margin-top: 15px;
                padding-top: 20px;
            }
        }

        &--end {
            border-bottom: 1px solid $gray-100;
            margin-bottom: 3px;
            padding-bottom: 3px;

            @media (--md) {
                margin-bottom: 20px;
                padding-bottom: 15px;
            }
        }
    }

    &__button {
        align-items: center;
        display: flex;
        font-size: $font-size-secondary;
        font-weight: 500;

        @if ($feature-redesign) {
            font-weight: normal;
            padding: 16px 0 0;

            @media (min-width: 544px) {
                padding-top: 14px;
            }
    
            @media (min-width: 768px) {
                padding-top: 20px;
            }
        }

        @else {
            padding: 20px;
        }

        position: relative;
        text-transform: uppercase;

        @media (--md) {
            font-size: $font-size-h4;
            letter-spacing: 0.8px;
        }

        &::before {
            font-size: 18px;
            margin: -10px 15px -10px 0;
        }

        &:hover {
            text-decoration: none;
        }
    }

    &__link {
        width: 100%;

        &_image {
            height: 2rem;
        }

        @if ($feature-redesign) {
            @include n11;
            z-index: 2;
        
            &:active {
                color: $earthen-brown;
            }
        }

        @else {
            padding: 10px;
            margin: -10px;
            position: relative;
        }
    }

    &__image {
        height: 36px;
        margin: -10px -10px -5px;

        @media (--md) {
            height: 42px;
            margin: -12px -10px -6px;
        }

        img {
            height: 100%;
            width: auto;
        }
    }

    &__arrow {
        align-items: center;
        background: none;
        border: 0;
        cursor: pointer;
        display: flex;
        font-size: 11px;
        height: 100%;
        justify-content: flex-end;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: $z-min;

        &:focus {
            outline: 0;
        }

        &::before {
            transition: transform $transition-ease;
        }

        @if ($feature-redesign) {
            padding: 0;
            margin-right: 8px;

            img,
            & {
                transform: rotate(180deg);
            }

            img {
                display: none;
            }
        }

        @else {
            padding: 22px 20px;
        }
    }

    &__item.show &__button {
        @if ($feature-redesign) {
            position: fixed;
            width: calc(100% - 72px);
            max-width: 480px;

            @media (map-get($next-header-breakpoints, lg)) {
                max-width: 252px;
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 100%;
                left: 0;
                height: 64px;
                width: 100%;
                background: $hush-white;
            }
        }
    }

    &__item.show &__arrow {
        z-index: 0;

        @if ($feature-redesign) {
            background: $hush-white;

            &::before {
                transform: rotate(180deg);
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 100%;
                left: 0;
                height: 16px;
                width: 100%;
                background: linear-gradient(to bottom, transparent, $hush-white);
            }

            img {
                display: inline-block;
            }
        }

        @else {
            &::before {
                transform: rotate(90deg);
            }
        }




    }

    &__menu {
        @if not $feature-redesign {
            @include toggle-content(0, 12px);
        }
                   
        color: $gray-400;
        font-size: $font-size-primary;
        letter-spacing: 0.8px;
        list-style: none;
        margin: 0;

        &::after {
            display: list-item;
        }

        @if ($feature-redesign) {
            @include n7;
            padding: 0;
            opacity: 0;
            transition: opacity 0.25s ease-in 70ms;

            &.hide {
                display: none;
            }

            &.menu-fade {
                opacity: 1;
            }
        }

        @else {
            padding: 0 30px;
        }
    }

    &__menu-list {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__menu-item {
        a {
            display: block;
            padding: 0.75rem 0;
            line-height: 24px;
        }

        @if ($feature-redesign) {
            a {
                line-height: inherit;
                padding: 6px 0;
            }
        }
    }

    &__menu-link {
        padding: 0.75rem 0;

        @if ($feature-redesign) {
            color: $textBlack;

            &:hover {
                color: $earthen-brown;
                text-decoration: none;
                
                &::after {
                    content: '\2192';
                    display: inline-block;
                    padding-left: 7px;
                    line-height: 1rem;
                }
            }
        }

        @else {

            &,
            &:hover {
                color: $gray-400;
            }
        }

        &::before {
            font-size: 20px;
            margin-right: 10px;
        }
    }

    &__category {
        @if ($feature-redesign) {
            padding-top: 60px;
            &--no-subcategory {
                padding-top: 58px;
            }
        }
    }

    &__sub-category {
        height: 40px;
        display: flex;
        align-items: center;

        font-family: $font-family-serif;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.08px;
        color: $boulder-grey;
        margin-top: 32px;

        @if ($feature-redesign) {
            @include n7;
            margin-top: 0;
            height: auto;
            padding: 0 0 6px;
        }
    }

    &__column-three {
        display: flex;
        flex-direction: column;

        &-link {
            display: inline-block;
            margin-top: 40px;

            &:nth-of-type(2) {
                margin-top: 16px;
                margin-bottom: 20px;
            }

            img {
                max-height: 288px;

                @if ($feature-redesign) {
                    width: 204px;
                    height: auto;
                }
            }

        }
    }
}

.b-hamburger-toggler {
    font-size: 13px;
    height: 33px;
    margin: -10px;
    padding: 10px;
    vertical-align: middle;
    color: $black;

    @media (--md) {
        &::before {
            content: icons-char(hamburger-bold);
            font-size: 16px;
        }
    }
}