.btn {
    border: 1px solid $black;
    display: inline-block;
    font-family: $font-family-theinhardt;
    font-size: $font-size-secondary;
    font-weight: 500;
    letter-spacing: 0.08em;
    line-height: 16px;
    outline: 0;
    padding: 13px 15px;
    text-align: center;
    transition: $transition-ease-short;
    transition-property: background-color, border-color, color;
    user-select: none;

    @if ($feature-redesign) {
        @include n8;
        &::first-letter {
            text-transform: uppercase;  
        }
    }

    @if not ($feature-redesign) {
        @media (--md-down) {
            font-size: 13px;
        }
    }

    &:hover {
        text-decoration: none;
    }

    &__text-active {
        display: none;
    }

    &.m-active & {
        &__text {
            display: none;
        }

        &__text-active {
            display: inline;
        }
    }
}

.btn-large {
    min-width: 192px;

    @media (--xxl) {
        font-size: 15px;
        padding: 20px 50px;
    }
}

.btn-medium {
    padding: 13px 30px;
}

.btn-small {
    padding: 8px 15px;
}

.btn-tiny {
    padding: 3.5px 15px;
}

.btn-primary {
    background-color: $black;
    color: $white;

    .non-touch &:not(:disabled):not(.m-disabled):hover {
        background-color: $white;
        color: $black;
    }

    &.m-disabled,
    &:disabled {
        background-color: $gray-100;
        border-color: $gray-100;
        color: $gray-300;
    }
}

a.btn-primary {
    .touch &:hover {
        color: $white;
    }
}

.btn-outline-primary {
    background-color: transparent;
    color: $black;

    &:not(:disabled):not(.m-disabled):not(.m-animate):hover {
        background-color: $black;
        color: $white;
    }

    .non-touch &.m-animate:not(:disabled):not(.m-disabled):hover {
        animation: bg-hover-black 1.5s ease-in-out;
    }

    &.m-disabled,
    &:disabled {
        border-color: $gray-200;
        color: $gray-300;
    }
}

.btn-secondary {
    background-color: transparent;
    border-color: $white;
    color: $white;
    padding: 13px 30px;
    text-align: center;

    .non-touch &:not(:disabled):not(.m-disabled):not(.m-animate):hover {
        background-color: $white;
        color: $black;
    }

    .non-touch &.m-animate:not(:disabled):not(.m-disabled):hover {
        animation: bg-hover-white 1.5s ease-in-out;
    }

    &:hover {
        color: $white;
        text-decoration: none;
    }

    &--black {
        border-color: $black;
        color: $black;
        text-align: center;

        &:hover {
            color: $black;
        }

        .non-touch &:not(:disabled):not(.m-disabled):hover {
            background-color: $black;
            color: $white;
        }
    }

    &--small {
        @media (--sm) {
            padding: 8px 15px;
            text-transform: none;
        }
    }
}

.btn-link {
    @include animated-link($invert: true);

    background-color: transparent;
    border: 0;
    display: inline;
    font-size: $font-size-primary;
    line-height: normal;
    padding: 0;

    @media (--lg-up) {
        font-size: $font-size-secondary;
    }
}

.btn-clr {
    background: none;
    border: 0;
    padding: 0;

    &:focus {
        outline: 0;
    }
}

@keyframes bg-hover-white {
    0%,
    100% {
        background-color: transparent;
    }

    50% {
        background-color: rgba($white, 0.5);
    }
}

@keyframes bg-hover-black {
    0%,
    100% {
        background-color: transparent;
    }

    50% {
        background-color: rgba($black, 0.4);
    }
}
