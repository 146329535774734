.b-cart-button {
    position: relative;
    user-select: none;

    &__text {
        &--added {
            display: none;
        }
    }

    &.m-added {
        background-color: $black;
        border-color: $black;
        color: $white;
    }

    &.m-hidden {
        display: none;
    }

    &.m-added &__text {
        &--default {
            display: none;
        }

        &--added {
            display: inline;
        }
    }

    &.m-widget-loading {
        color: transparent;
        pointer-events: none;
    }
}
