.h1-d {
    font-family: $font-family-tiempos-fine;
    font-weight: 700;
    @if ($feature-redesign) {
        font-family: $font-family-serif;
        font-weight: bold;
    }
    font-size: 50px;
    letter-spacing: 0.05em;
    line-height: 1.2;
    text-transform: none;
}

.h2-a {
    font-family: $font-family-tiempos-fine;
    font-size: 30px;
    font-weight: 700;
    @if ($feature-redesign) {
        font-family: $font-family-serif;
        font-weight: bold;
    }
    letter-spacing: 0.75px;
    line-height: 1.5;
    text-transform: none;

    @media (--md) {
        font-size: 26px;
    }

    @media (--sm) {
        font-size: 18px;
    }
}
