$popout-width: 396px;

.b-popout {
    &__content {
        background: $white;
        bottom: 0;
        display: flex;
        flex-direction: column;
        font-size: $font-size-primary;
        width: $popout-width;

        pointer-events: none;
        position: fixed;
        right: 0;
        top: 0;
        transform: translate3d(100%, 0, 0);
        transition: transform $transition-ease-short 0s;
        z-index: 5;
    }

    &::after {
        overscroll-behavior: contain;
        background-color: #000;
        content: "";
        left: -10%;
        opacity: 0;
        pointer-events: none;
        position: fixed;
        top: -10%;
        transition: opacity ease-out 0.4s, z-index linear 0s 0.4s;
        width: 120%;
        z-index: 4;
    }
}

.b-popout--open {
    .b-popout__content {
        pointer-events: initial;
        transform: translate3d(0, 0, 0);
    }

    &::after {
        opacity: 0.5;
    }
}