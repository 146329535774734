@use "sass:math";

@mixin pseudo-loader(
    $color-primary: #eaac9d,
    $color-secondary: #044456,
    $size: 48px
) {
    &::after,
    &::before {
        border: #{math.div($size, 6)} solid;
        border-color: $color-primary;
        border-radius: 100%;
        content: "";
        height: $size;
        left: 50%;
        margin: -#{$size * 0.5} 0 0 -#{$size * 0.5};
        position: absolute;
        top: 50%;
        width: $size;

        @content;
    }

    &::after {
        animation: loading-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite;
        border-color: $color-secondary transparent transparent;
    }

    @keyframes loading-spin {
        100% {
            transform: rotate(360deg);
        }
    }
}

.b-loader {
    $animation-time: 1.8s;
    $animation-delay: math.div($animation-time, 3);

    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 8px;
    justify-content: center;
    line-height: 1;
    text-align: center;
    user-select: none;

    body > & {
        z-index: $z-modal;
    }

    &::before,
    &::after,
    .b-loader__text::before {
        @include icons-styles;

        content: icons-char("point");
    }

    &::before,
    &::after,
    .b-loader__text {
        animation: loading-point $animation-time cubic-bezier(0.6, 0.2, 0, 0.8) infinite;
        color: $gray-300;
        margin: 0 4px;
    }

    .b-loader__text {
        animation-delay: $animation-delay;
    }

    &::after {
        animation-delay: 2 * $animation-delay;
    }

    &--bound {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &--quickview,
    &--page {
        height: 120%;
        left: -10%;
        position: fixed;
        top: -10%;
        width: 120%;
        z-index: $z-loader;
    }

    &--quickview {
        background-color: rgba($black, 0.5);
    }

    &--page,
    &--bound {
        background-color: rgba($white, 0.8);
    }

    &--inside {
        background-color: rgba($white, 0.8);
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: $z-min;
    }

    &--inline {
        min-height: 16px;
        order: 999;
        position: relative;
        width: 100%;
    }

    &--button {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        &::before,
        &::after,
        .b-loader__text {
            animation-name: loading-point-white;
        }
    }

    @keyframes loading-point {
        0%,
        #{percentage(math.div(2, 3))},
        100% {
            color: $gray-300;
        }

        #{percentage(math.div(1, 3))} {
            color: $black;
        }
    }

    @keyframes loading-point-white {
        0%,
        #{percentage(math.div(2, 3))},
        100% {
            color: $gray-400;
        }

        #{percentage(math.div(1, 3))} {
            color: $white;
        }
    }
}

@keyframes loading-bg {
    0% {
        background-color: transparent;
    }

    50% {
        background-color: $gray-100;
    }
}

@keyframes loading-border {
    0% {
        border-color: $gray-100;
    }

    50% {
        border-color: $gray-300;
    }
}

@keyframes loading-opacity {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
}

@keyframes loading-underline {
    0%,
    100% {
        transform: scale(0, 1);
        transform-origin: 0 50%;
    }

    79.9% {
        transform-origin: 0 50%;
    }

    80% {
        transform: scale(1, 1);
        transform-origin: 100% 50%;
    }

    99.9% {
        transform-origin: 100% 50%;
    }
}

.m-widget-loading {
    img {
        opacity: 0;
    }
}

@supports (-ms-ime-align:auto) {
    .m-edge-loading {
        img {
            opacity: 0;
        }
    }
}
