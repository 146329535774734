.search-container {
    display: inline-grid;
    position: relative;
    background-color: $white-opacity;
    max-width: $search-width;
    width: 100%;
    grid-template-columns: 25% 75%;
    grid-template-rows: #{$search-gutter * 2.2} auto;
    letter-spacing: $letter-spacing-search;
    padding-top: #{$search-gutter * 0.8};

    @if ($feature-redesign) {
        background-color: $hush-white;

        .b-hamburger & {
            display: none;
        }

        .search-mode .b-hamburger & {
            display: block;
        }
    }

    @media (--md-down) {
        grid-template-columns: 30% 70%;
        grid-template-rows: #{$search-gutter * 2} auto;
    }

    @media (--sm) {
        padding-top: 0;
        grid-template-columns: auto;
        grid-template-rows: auto auto;
    }

    .b-header-icons__button {
        position: absolute;
        top: $search-close-button-gutter;
        right: $search-close-button-gutter;
        color: $gray-400;
        font-size: $font-size-secondary;
        z-index: 1;

        @media (--md-down) {
            font-size: $font-size-h3;
        }

        @if ($feature-redesign) {
            font-size: 14px;
            color: $black;
            &:hover {
                color: $earthen-brown;
            }
        }

        &::before {
            content: icons-char(close);
        }
    }

    .productSuggestions {
        grid-row: 1 / span 2;
        grid-column: 2;
        height: 41vw;

        @media (min-width: $search-breakpoint) {
            height: $search-max-height;
        }

        @media (--sm) {
            display: none;
        }

        .b-carousel--search {
            margin-bottom: 0;
        }
    }

    .generalSuggestions {
        font-weight: $font-weight-standart;

        @if ($feature-redesign) {
            font-weight: normal;
        }
        padding-bottom: #{$search-gutter * 1.25};
        padding-left: #{$search-gutter * 2.1};
        padding-top: 0;

        @media (--md-down) {
            padding-left: #{$search-gutter * 1.5};
        }

        @media (--sm) {
            padding-left: $search-gutter;
            padding-top: #{($search-gutter * 2 * 0.2) - $search-text-gutter};
        }

        .doYouMeanSuggetions {
            font-size: $font-size-h4;
            margin: #{$search-gutter * 0.2} 0;

            @media (--md-down) {
                font-size: $font-size-primary;
            }

            a {
                padding: 0;
            }
        }

        .categorySuggestions,
        .searchPhraseSuggestions {
            > * {
                padding: $search-text-gutter 0;
                font-size: $font-size-search;
                align-self: flex-start;

                @media (--md-down) {
                    font-size: $font-size-h4;
                }

                @media (--sm) {
                    font-size: $font-size-search;
                }
            }
        }

        .general-suggestions-title {
            color: $black;
        }

        .search-phrase-value {
            color: $dark-gray;
        }
    }
}

.b-header-search {
    display: flex;
    position: relative;
    align-items: flex-start;
    flex: 1;
    padding: 0;
    padding-top: #{$search-gutter * 1.2};
    padding-left: #{$search-gutter * 1.5};
    padding-bottom: #{$search-gutter * 3 * 0.1};

    @media (--sm) {
        padding-right: $search-gutter;
        padding-bottom: $search-text-gutter;
    }

    @media (--md-down) {
        padding-left: $search-gutter;
        padding-top: $search-gutter;
    }

    &__field {
        flex: 1;
        width: 125px;
    }

    &__input {
        background: none;
        border-color: transparent transparent $black;
        border-style: solid;
        border-width: 0 0 1px;
        line-height: 2;
        width: 100%;
        border-bottom-color: $gray-300;
        font-size: $font-size-search;
        font-weight: 300;
        height: $search-input-height;
        letter-spacing: 0.08em;
        padding: 0 $search-input-gutter-side;
        text-align: left;
        color: $dark-gray;

        @if ($feature-redesign) {
            @include n3;
        }

        @media (--md-down) {
            font-size: $font-size-h4;
        }

        @media (--sm) {
            font-size: $font-size-search;
        }
    }

    &__submit {
        background: none;
        border: 0;
        font-size: $font-size-h3;
        line-height: 1;
        padding: 0;
        position: relative;
        width: 20px;
        z-index: $z-min;
        margin: 7px -22px 0 0;
        @if ($feature-redesign) {
            &.i-search {
                display: none;
            }
        }
    }

    &__close {
        font-size: $font-small-icon;
        margin: #{$search-gutter * 0.1} 0 0 #{$search-gutter * -0.5};
        color: $gray-900;
        padding: #{$search-gutter * 0.1};
        display: none;

        .search-toggled & {
            display: block;
        }
    }

    &__error {
        bottom: 15px;
        color: $gray-900;
        font-size: 12px;
        font-weight: 300;

        @if ($feature-redesign) {
            font-weight: normal;
        }
        left: 0;
        letter-spacing: 0.03em;
        position: absolute;
        right: 0;
        text-align: center;

        @media (--lg-down) {
            bottom: 10px;
        }

        @media (--sm) {
            bottom: 5px;
        }
    }
}
