@mixin icons-styles($size: 1em) {
    display: inline-block;
    font-family: 'icons', sans-serif;
    font-size: $size;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: none;
    text-transform: none;
    vertical-align: top;
}

@function icons-unicode($str) {
    @return unquote('\"') + unquote(str-insert($str, '\\', 1)) + unquote('\"');
}

%icons {
    @include icons-styles;
}

@function icons-char($filename) {
    $char: '';
    @if $filename == applepay {
        $char: icons-unicode('EA01');
    }
    @if $filename == arrow-down {
        $char: icons-unicode('EA02');
    }
    @if $filename == arrow-left {
        $char: icons-unicode('EA03');
    }
    @if $filename == arrow-right {
        $char: icons-unicode('EA04');
    }
    @if $filename == arrow-up {
        $char: icons-unicode('EA05');
    }
    @if $filename == arrow {
        $char: icons-unicode('EA06');
    }
    @if $filename == bag {
        $char: icons-unicode('EA07');
    }
    @if $filename == checkbox {
        $char: icons-unicode('EA08');
    }
    @if $filename == close-bold {
        $char: icons-unicode('EA09');
    }
    @if $filename == close-thin {
        $char: icons-unicode('EA0A');
    }
    @if $filename == edit {
        $char: icons-unicode('EA0B');
    }
    @if $filename == email {
        $char: icons-unicode('EA0C');
    }
    @if $filename == facebook {
        $char: icons-unicode('EA0D');
    }
    @if $filename == filters {
        $char: icons-unicode('EA0E');
    }
    @if $filename == globe {
        $char: icons-unicode('EA0F');
    }
    @if $filename == hamburger-bold {
        $char: icons-unicode('EA10');
    }
    @if $filename == hamburger {
        $char: icons-unicode('EA11');
    }
    @if $filename == instagram {
        $char: icons-unicode('EA12');
    }
    @if $filename == lock {
        $char: icons-unicode('EA13');
    }
    @if $filename == mastercard {
        $char: icons-unicode('EA14');
    }
    @if $filename == paypal {
        $char: icons-unicode('EA15');
    }
    @if $filename == pinterest {
        $char: icons-unicode('EA16');
    }
    @if $filename == point {
        $char: icons-unicode('EA17');
    }
    @if $filename == search {
        $char: icons-unicode('EA18');
    }
    @if $filename == star-stroke {
        $char: icons-unicode('EA19');
    }
    @if $filename == star {
        $char: icons-unicode('EA1A');
    }
    @if $filename == success {
        $char: icons-unicode('EA1B');
    }
    @if $filename == twitter {
        $char: icons-unicode('EA1C');
    }
    @if $filename == close {
        $char: icons-unicode('D7');
    }
    @if $filename == user-in {
        $char: icons-unicode('EA1D');
    }
    @if $filename == user {
        $char: icons-unicode('EA1E');
    }
    @if $filename == visa {
        $char: icons-unicode('EA1F');
    }
    @if $filename == warning {
        $char: icons-unicode('EA20');
    }
    @if $filename == whatsapp {
        $char: icons-unicode('EA21');
    }
    @if $filename == wishlist-filled {
        $char: icons-unicode('EA22');
    }
    @if $filename == wishlist {
        $char: icons-unicode('EA23');
    }
    @return $char;
}

@mixin icons($filename, $insert: before, $extend: true, $size: 16px) {
    &:#{$insert} {
        content: icons-char($filename);
        @if $extend {
            @extend %icons;
        } @else {
            @include icons-styles($size);
        }
        @content;
    }
}
