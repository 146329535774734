.b-footer-social {
    &__list {
        align-items: center;
        display: flex;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    &__link {
        transition: 0.3s;

        &:hover {
            opacity: 0.5;
        }
    }

    &__item {
        margin-right: 60px;

        &:last-child {
            margin-right: 0;
        }
    }

    svg {
        vertical-align: top;
    }
}
