.t-link {
    @include animated-link;
}

.t-link--invert {
    @include animated-link($invert: true);
}

.t-link--white {
    @include animated-link($white);
}

.t-link--white-invert {
    @include animated-link($white, $invert: true);
}

.t-link--gray {
    @include animated-link($gray-300);

    color: $gray-300;
}

.t-link--gray-invert {
    @include animated-link($gray-300, $invert: true);

    color: $gray-300;
}

@keyframes underline-hover {
    0% {
        transform: scale(0, 1);
        transform-origin: 0 50%;
    }

    50% {
        transform: scale(1, 1);
        transform-origin: 0 50%;
    }

    51% {
        transform: scale(1, 1);
        transform-origin: 100% 50%;
    }

    100% {
        transform: scale(0, 1);
        transform-origin: 100% 50%;
    }
}

@keyframes underline-hover-invert {
    0% {
        transform: scale(1, 1);
        transform-origin: 100% 50%;
    }

    50% {
        transform: scale(0, 1);
        transform-origin: 100% 50%;
    }

    51% {
        transform: scale(0, 1);
        transform-origin: 0 50%;
    }

    100% {
        transform: scale(1, 1);
        transform-origin: 0 50%;
    }
}
