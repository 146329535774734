.custom-select-container {
    position: relative;
    user-select: none;

    select {
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;

        @media (--md-up) {
            pointer-events: none;
        }
    }

    .form-group.is-invalid & {
        ~ .invalid-feedback {
            display: block;
        }
    }
}

.custom-select-opener {
    @include form-control;
    @include icons(arrow-down, after) {
        color: $black;
        font-size: 8px;
        margin-top: -4px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: rotate(-90deg);

        @media (--sm) {
            font-size: 6px;
        }
    }

    cursor: pointer;
    display: block;
    overflow: hidden;
    padding-right: 20px;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;

    .custom-select-container.is-empty & {
        color: $gray-500;
    }

    .custom-select-container.is-open &::after {
        transform: rotate(0deg);
    }

    .custom-select-container.is-disabled & {
        @include form-control--disabled;

        &::before {
            color: $gray-500;
        }
    }

    .form-group.is-invalid & {
        border-color: $red;
    }

    .l-product--gift-certificate & {
        border-width: 1px;
        padding: 10px 20px;

        &::after {
            right: 15px;
            transform: rotate(0);
        }
    }

    @media (--sm) {
        pointer-events: none;
    }
}

.custom-select-panel {
    @include custom-scrollbar(
        $thumb-color: $gray-200,
        $scrollbar-width: 3px,
        $scrollbar-height: 3px,
        $arrows: false
    );

    background-color: $white;
    border: 1px solid $gray-500;
    color: $black;
    display: none;
    font-size: 16px;
    letter-spacing: 0.03em;
    line-height: 1;
    margin-top: -1px;
    max-height: 272px;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: $z-min;

    @media (--md-down) {
        font-size: 14px;
        max-height: 248px;
    }

    .custom-select-container.is-open & {
        display: block;
    }
}

.custom-select-option {
    cursor: pointer;
    padding: 10px 20px;

    @media (--md-down) {
        padding-left: 14px;
        padding-right: 14px;
    }

    &.has-focus {
        background-color: $gray-100;
    }
}

.custom-select-optgroup::before {
    content: attr(data-label);
    display: block;
}
