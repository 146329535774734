.grecaptcha-badge {
    visibility: hidden;
}

.google-privacy_text {
    color: rgb(162, 160, 160);
    font-size: 0.9em;
    padding-bottom: 15px;

    @if ($feature-redesign) {
        @include n9;
        color: $black;
    }
}

.b-guest-login-form .google-privacy_text {
    margin-top: 12px;
}

@media only screen and (max-width: 768px) {
    .l-account-login #login .google-privacy_text {
        margin-top: 12px;
    }
}

@media only screen and (min-width: 768px) {
    .l-account-login #register .b-registration-form__actions {
        flex-wrap: wrap;
    }

    .l-account-login #register .google-privacy_text {
        padding: 0;
    }
}
