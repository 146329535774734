.b-wishlist-button {
    align-items: center;
    background: none;
    color: $black;
    border: none;
    display: inline-flex;
    font-size: 0;
    height: 18px;
    justify-content: center;
    line-height: 18px;
    padding: 0;
    vertical-align: top;
    width: 18px;

    &::before {
        font-size: 16px;
    }

    &.m-disabled {
        color: inherit;
        pointer-events: none;
    }

    &.m-active {
        &::before {
            content: icons-char(wishlist-filled);
        }
    }
}

.b-product-quickview {
    .b-wishlist-button {
        &::before {
            font-size: 16px;

            @media (--md-up) {
                font-size: 13px;
            }

            @media (--lg-up) {
                font-size: 18px;
            }
        }
    }
}
