.b-product-card {
    display: flex;

    &__image {
        margin-right: 20px;
        width: 136px;
    }

    &__content {
        flex: 1;
    }

    &__header {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
    }

    &__name {
        font-size: $font-size-h4;
        font-weight: 500;
        letter-spacing: 0.03em;
        margin-bottom: 5px;
        padding-right: 20px;
        @if ($feature-redesign) {
            @include n5;
        }
    }

    &__remove {
        background: none;
        border: 0;
        margin: 0;
        padding: 0;
    }

    &__price {
        font-size: $font-size-h4;
        margin-bottom: 20px;
    }

    &__quantity {
        margin-top: 20px;

        @if ($feature-redesign) {
            @include n5;
        }

        &-wrapper & {
            font-size: $font-size-h3;
            margin: 0;

            @media (--sm) {
                font-size: 13px;
            }
        }

        span {
            display: none;
        }
    }

    &__quantity-wrapper {
        position: absolute;
        bottom: 30px;
        right: 0;

        .b-product-card__quantity-label {
            @media (--lg-up) {
                display: none;
            }
        }

        @media (--sm) {
            padding-left: 100px;
        }

        @media (--md) {
            padding-left: 170px;
        }

        @media (--lg-up) {
            position: static;
        }
    }

    &__attribute-group {
        display: flex;
        gap: 4px;
    }

    &__attribute-name {
        display: none;
    }

    &__attribute {
        font-weight: 300;
        margin-top: 5px;
        @if ($feature-redesign) {
            @include n10;
        }
    }

    &__option,
    &__availability,
    &__total {
        margin-top: 15px;
    }

    &__total {
        font-weight: 500;

        @if ($feature-redesign) {
            @include n10;
             
        }
    }

    &--qty-1 & {
        &__total {
            display: none;
        }
    }

    .b-product-availability {
        font-size: inherit;
        @if ($feature-redesign) {
            @include n10;
        }
    }

    .bundled-line-item__image {
        width: 100%;
        height: auto;
    }
}
