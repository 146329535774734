@use "sass:math";

.b-carousel {
    $selector: &;

    &--search {
        #{$selector} {
            &__container {
                @each $breakpoint, $paddings in $carousel-paddings-search {
                    $left: nth($paddings, 1);
                    $right: nth($paddings, 2);

                    @media (--#{$breakpoint}) {
                        padding-left: $left;
                        padding-right: $right;

                        @if (($right - $left) > 0) {
                            &::before {
                                margin-right: -#{$right - $left};
                            }
                        }
                    }
                }

                margin-top: #{-$carousel-gutter * 3};

                &::before {
                    border-top: none;
                }
            }

            &__title {
                font-family: $font-family-tiempos-fine;
                font-size: $font-size-h2;
                font-weight: $font-weight-bolder;
                @if ($feature-redesign) {
                    font-family: $font-family-serif;
                }
                margin-bottom: #{$search-gutter * 0.2};

                @media (--md-down) {
                    font-size: $font-size-tablet-search;
                }
            }

            &__track {
                overflow: hidden;
            }

            &__slide {
                @each $breakpoint, $gutter in $search-carousel-gutters {
                    @media (--#{$breakpoint}) {
                        padding-left: $gutter;
                        padding-right: $gutter;
                    }
                }

                .b-product-tile__images {
                    margin-bottom: #{$carousel-gutter * 1.5};
                }

                &--asset {
                    height: auto;

                    .js-banner-wrapper {
                        overflow: hidden;
                        position: relative;
                        vertical-align: top;

                        &::before {
                            content: "";
                            float: left;
                            padding-bottom: trim-float(percentage($picture-search-ratio));
                        }
                    }

                    > * {
                        background-color: $green-dark;

                        .h2 {
                            font-size: $font-size-h2;

                            @media (--md-down) {
                                font-size: $font-size-tablet-search;
                            }
                        }
                    }

                    &--double {
                        .js-banner-wrapper {
                            width: 200%;

                            &::before {
                                padding-bottom: trim-float(percentage($picture-search-ratio * 0.5));
                            }
                        }
                    }
                }

                .b-product-tile__top {
                    margin-bottom: 0;
                }

                .b-product-tile__bottom {
                    font-size: $font-size-primary;
                    font-weight: $font-weight-bold;

                    @if ($feature-redesign) {
                        font-weight: normal;
                    }
                    letter-spacing: #{math.div($letter-spacing-search * 5, 3)};
                    height: #{$search-gutter * 1.5};

                    .b-product-tile__name {
                        margin-bottom: #{$carousel-gutter * 0.5};
                    }
                }
            }

            &__arrows {
                top: unset;
                right: unset;
                position: unset;
            }

            &__arrow {
                position: absolute;
                scale: 1.5;
                margin: $carousel-arrow-gutter;
                padding: $carousel-arrow-gutter;
                top: calc(50% - #{$search-gutter * 0.8});

                @media (--md-down) {
                    top: calc((100% - #{$search-gutter * 1.6}) / 2);
                }

                &--disabled {
                    display: none;
                }

                &--prev {
                    left: -$carousel-arrow-offset;
                    @if ($feature-redesign) {
                        scale: 1;
                        .b-carousel__arrow-icon::before {
                            transform: scale(-1);
                            content: icons-char("arrow");
                        }
                    }
                }

                &--next {
                    right: -$carousel-arrow-offset;
                    @if ($feature-redesign) {
                        scale: 1;
                        .b-carousel__arrow-icon::before {
                            content: icons-char("arrow");
                        }
                    }
                }
            }
        }
    }
}
