.l-footer {
    background: $green-dark;
    color: $white;
    margin-top: auto;
    

    &__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @if ($feature-redesign) {
            border-top: 1px solid black;
        }
    }

    &__navigation {
        @media (--lg-up) {
            margin-top: 7px;
        }

        @media (--md-down) {
            width: 100%;
        }

        @media (--sm) {
            &::before {
                border-top: 1px solid $white;
                content: "";
                display: block;
                margin: 0 -#{map-get($container-gutters, sm)} 13px;
            }
        }
    }

    &__newsletter,
    &__social-links,
    &__country-selector {
        @media (--lg-up) {
            max-width: 402px;
            min-width: 344px;
            width: 28%;
        }
    }
    @if ($feature-redesign) {
        background: $white;
        color: $black;
        padding: 0;
        font-family: $font-family-serif;
        order: 1;

        &__sign-up {
            padding-top: 80px;
            @media (--lg-up) {
                padding-top: 160px;
            }

            width: 50%;
            @media (--md-down) {
                width: 100%;
            }
        }

        &__newsletter {
            width: 100%;
            max-width: 100%;
            background-color: #FCF9F6;
            padding: 2.5rem ;

            @if ($feature-redesign) {
                padding-top: 0;
            }
        }

        .container {
            margin: 0;
            padding: 2.5rem;
            width: 100%;
            max-width: 100%;
        }
        &__container {
            display: flex;
            gap: 2.5rem;
            flex-direction: column;
        }

        @media screen and (min-width: 1200px) {
            &__newsletter {
                width: 100%;
                max-width: 100%;
            }
        }
    } @else {
        padding: 45px 0;

        @media (--md) {
            padding-bottom: 40px;
            padding-top: 40px;
        }

        @media (--sm) {
            padding-bottom: 30px;
            padding-top: 30px;
        }
    }
    &__newsletter {
        order: -1;

        @media (--lg-up) {
            display: flex;
            flex-direction: column;
            order: 0;
        }

        @media (--md-down) {
            width: 100%;
        }

    }

    &__sign-up {
        margin-bottom: 25px;

        @media (--lg-up) {
            margin-bottom: 15px;
        }
    }

    &__social-links {
        @media (--md) {
            margin-bottom: 45px;
        }

        @media (--sm) {
            margin-bottom: 20px;
        }
    }

    &__bottom {
        width: 100%;

        @media (--md-up) {
            position: relative;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin-top: 1px;
        }

        @media (--lg-up) {
            padding-top: 112px;
        }
    }

    &__bcorp-logo {
        height: 72px;
        svg {
            height: 100%;
        }

        @media (--lg-up) {
            position: absolute;
            top: 0;
        }
    }

    &__country-selector {
        margin-top: -5px;
    }

    &__copyright {
        @media (--md) {
            margin-top: 32px;
        }

        @media (--sm) {
            margin-top: 20px;
        }
        @if ($feature-redesign) {
            color: $black;
        }
    }

    @if ($feature-redesign) {
        [data-lastpass-icon-root] {
             display: none !important;
        }

        [type=submit]:not(:disabled):hover {
            color: $earthen-brown;
        }
     }
}
