.b-gift-certificate {
    display: flex;
    flex-direction: column;

    @media (--lg-up) {
        flex-direction: row;
    }

    &__column {
        flex-basis: 50%;
        flex-grow: 1;
        margin-top: 30px;
        padding-right: 20px;
    }

    &__title {
        display: block;
        padding-bottom: 5px;
    }

    &__text {
        @if ($feature-redesign) {
            font-family: $font-family-sans;
        }
        color: $black;
        font-size: $font-size-secondary;
        letter-spacing: 0;
    }

    .form-check-label {
        position: relative;
    }

    .form-check-input + .form-check-label::before {
        width: 15px;
        height: 15px;
        min-width: 15px;
        background: none;
        border-color: $black;
    }

    .form-check-input + .form-check-label::after {
        content: '';
        width: 9px;
        height: 9px;
        min-width: 9px;
        background: $black;
        position: absolute;
        top: 4px;
        left: 3px;
        border-radius: 50%;
        opacity: 0;
    }

    .form-check-input:checked + .form-check-label::after {
        opacity: 1;
    }
}
