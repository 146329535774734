@mixin make-container(
    $gutter: $grid-gutter-width,
    $gutters: $container-gutters,
    $inside-container: false
) {
    @if ($inside-container == false) {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

    @each $breakpoint, $gutter in $gutters {
        @media (--#{$breakpoint}) {
            padding-left: $gutter;
            padding-right: $gutter;

            @if ($inside-container) {
                margin-left: -$gutter;
                margin-right: -$gutter;
            }
        }
    }
}

@mixin make-container-paddings(
    $gutters: $container-gutters
) {
    @each $breakpoint, $gutter in $gutters {
        @media (--#{$breakpoint}) {
            padding-left: $gutter;
            padding-right: $gutter;
        }
    }
}

@mixin make-row($gutter: $grid-gutter-width) {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$gutter * 0.5;
    margin-right: -$gutter * 0.5;

    @each $key, $gutter in $grid-gutters {
        @media (--#{$key}) {
            margin-left: -$gutter * 0.5;
            margin-right: -$gutter * 0.5;
        }
    }
}

@mixin make-col-ready($gutter: $grid-gutter-width) {
    padding-left: $gutter * 0.5;
    padding-right: $gutter * 0.5;
    position: relative;
    width: 100%;

    @each $key, $gutter in $grid-gutters {
        @media (--#{$key}) {
            padding-left: $gutter * 0.5;
            padding-right: $gutter * 0.5;
        }
    }
}
