.b-header-promo {
    @include toggle-content;

    background-color: $pink;

    @if ($feature-redesign) {
        font-weight: normal;
        @include n10;
    } @else {
        font-size: $font-size-secondary;
    }

    letter-spacing: 0;
    line-height: 1;
    position: relative;
    text-align: center;

    @if ($feature-redesign) {
        z-index: 4;
        .opened-minicart & {
            z-index: 3;
        }
    } @else {
        z-index: $z-header-promo;
    }
    
    @if not ($feature-redesign) {
        @media (--xl) {
            font-size: $font-size-primary;
        }

        @media (--xxl) {
            font-size: 16px;
        }
    }

    &__text {
        @include line-clamping(2);

        max-height: 2 * $font-size-secondary + 18px;
        position: relative;
        padding: 9px 30px;

        @media (--xl) {
            max-height: 2 * $font-size-primary + 18px;
        }

        @media (--xxl) {
            max-height: 2 * 16px + 40px;
            padding-bottom: 20px;
            padding-top: 20px;
        }

        @each $breakpoint, $gutter in $header-gutters {
            @media (--#{$breakpoint}) {
                padding-left: $gutter;
                padding-right: $gutter;
            }
        }
    }

    &__link {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__button {
        background: none;
        border: 0;
        font-size: 11px;
        line-height: 1;
        padding: 10px;
        position: absolute;
        right: 0;
        top: 0;

        @media (--xxl) {
            top: 12px;
        }

        @each $breakpoint, $gutter in $header-gutters {
            @media (--#{$breakpoint}) {
                right: $gutter - 10px;
            }
        }
    }

    &__switcher {
        text-decoration: underline;
        cursor: pointer;
    }

    @if ($feature-redesign) {
        div#GlobaleFreeShippingBannerContainer[style] {
            font-weight: normal;
            font-family: $font-family-sans-serif!important;
            font-size: 0.96rem !important;
            font-weight: 500 !important;
            line-height: 1.3rem !important;
            letter-spacing: 0px !important;
            background-color: #B2D6E4 !important;
            padding: 0px!important;
            height: auto!important;
        }
        div#GlobaleFreeShippingBannerContainer div[style] {
            font-family: $font-family-sans-serif!important;
            height: 34px!important;
            font-size: 0.96rem !important;
            box-sizing: border-box!important;
            font-weight: 500 !important;
            line-height: 34px!important;
            padding: 0px!important;
            margin: 0px!important;
            padding: 0px!important;
            margin: 0px!important;
        }
    }
}
