.b-product-price {
    word-spacing: 4px;
    @if ($feature-redesign) {
        @include n5;
    }

    .strike-through {
        color: $gray-300;
        display: inline-block;
        text-decoration: line-through;
    }

    .l-product--bundle & {
        display: flex;
        align-items: baseline;

        .strike-through {
            display: none;
        }

        .saving {
            order: 1;
            color: $gray-300;
            font-size: 14px;
        }

        .sales {
            order: 0;
            margin-right: 10px;
            word-spacing: 4px;

            @if ($feature-redesign) {
                 
                @include n5;
            }
        }
    }
}

.red-price {
    color: #cb1b1c !important;
}
