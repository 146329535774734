@use "sass:math";

.b-carousel {
  $selector: &;

  &--next {
    #{$selector} {
      &__container {
        width: 100%;
        padding-left: 150px;
        padding-right: 150px;
        max-width: calc(1376px + (2 * 150px));

        @media (--md) {
          padding-left: 20px;
          padding-right: 0;
          margin-left: 0;
          margin-right: 0;
        }

        @media (--sm) {
          padding-left: 19px;
          padding-right: 0;
        }
      }

      &__title {
        @include n3;
        text-transform: uppercase;
        margin-bottom: 8.083rem;

        @media (--sm) {
          margin-bottom: 4.48rem;
        }
      }

      &__track {
        @media (--lg-up) {
          margin: 0 auto;
        }
      }

      &__arrows {
        @media (--md) {
          display: none;
        }

        @media (--sm) {
          display: none;
        }
      }

      &__arrow {
        &__mid {
          background-color: transparent;

          #{$selector} {
            &__arrow-icon {
              color: $black;
              @include icons(arrow);
            }
          }
        }

        &.b-carousel__arrow {
          &--disabled {
            background-color: transparent;

            #{$selector} {
              &__arrow-icon {
               display: none;
              }
            }
          }

          &--prev {
            #{$selector} {
              &__arrow-icon::before {
                transform: rotate(180deg);
              }
            }
          }

          &--next {
            right: 0;
          }
        }
      }
    }
  }
}
