.b-quantity-field {
    &__body {
        display: flex;
        width: 80px;
    }

    &__input-wrapper {
        border: 1px solid $gray-100;
        flex: 1;
    }

    &__input {
        appearance: none;
        -moz-appearance: textfield;
        background: $white;
        border: 0;
        font-size: 15px;
        font-weight: 300;
        height: 30px;
        line-height: 20px;
        padding: 5px;
        text-align: center;
        width: 100%;

        @if ($feature-redesign) {
            @include n5;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            appearance: none;
            margin: 0;
        }
    }

    &__button {
        background: none;
        border: 0;
        height: 32px;
        margin: 0;
        padding: 0;
        position: relative;
        width: 30px;

        &:first-child {
            margin-left: -10px;
        }

        &:last-child {
            margin-right: -10px;
        }

        &::before,
        &::after {
            background-color: $black;
            bottom: 0;
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;
            top: 0;
        }

        &::before {
            content: "";
            height: 1px;
            width: 7px;
        }

        &.m-plus::after {
            content: "";
            height: 7px;
            width: 1px;
        }

        &:disabled {
            &::before,
            &::after {
                background-color: $gray-200;
            }
        }
    }
}
